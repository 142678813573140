import React, {useState,useRef,useEffect} from 'react';
import AuthService from '../Services/AuthService';
import Message from '../Components/Message';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
const schema = yup.object().shape({
    username:yup
    .string()
    .required("ce champ est obligatoire"),
    password:yup
    .string()
    .required("ce champ est obligatoire")
    .min(8,"Le mot de passe doit contenir plus de 8 caractères!"),
    passwordConfirmation: yup.string()
     .oneOf([yup.ref('password'), null], 'les mots de passe doivent correspondre'),
    role:yup
    .string()
    .required("ce champ est obligatoire")
    
  });


const Register = props=>{
    const [user,setUser] = useState({username: "", password : "", role : "admin",passwordConfirmation:""});
    const [message,setMessage] = useState(null);
    let timerID = useRef(null);
    const { register , handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
      })

    useEffect(()=>{
        return ()=>{
            clearTimeout(timerID);
        }
    },[]);

    const onChange = e =>{
        setUser({...user,[e.target.name] : e.target.value});
    }

    const resetForm = ()=>{
        setUser({username : "", password : "",role : "admin", passwordConfirmation:""});
    }

    const onSubmit = e =>{
        
        AuthService.register(user).then(data=>{
            const { message } = data;
            setMessage(message);
            resetForm();
            if(!message.msgError){
                timerID = setTimeout(()=>{
                    props.history.push('/gest-users');
                },2000)
            }
        });
    }



    return(
        <div className="container">
             <h3>Ajouter Utilisateur</h3>
            {message ? <Message message={message}/> : null}
            <form onSubmit={handleSubmit(onSubmit)}  >
               <div className="form-group">
                   <label htmlFor="name"  >Nom ou E-mail</label>
                <input type="text" 
                       name="username" 
                       value={user.username}
                       onChange={onChange} 
                       ref={register}
                       className={`${errors.username? `form-control is-invalid`: `form-control` }`}
                       placeholder="Enter Username"/>
                       <div className="error ">{errors.username?.message}</div>
                       </div>
                       <div className="form-group">
                <label htmlFor="password" >Mot De Passe </label>
                <input type="password" 
                       name="password"
                       value={user.password} 
                       onChange={onChange} 
                       ref={register}
                       className={`${errors.password? `form-control is-invalid`: `form-control` }`}
                       placeholder="Enter Password"/>
                       <div className="error ">{errors.password?.message}</div>
                </div>
                <div className="form-group">
           
                <label htmlFor="name">Confirmation Mot De Passe</label>
                 <input 
                 type="password"
                 name="passwordConfirmation"
                 value={user.passwordConfirmation} 
                 onChange={onChange}
                 ref={register} 
                 placeholder="Enter Password" 
                 className={`${errors.passwordConfirmation? `form-control is-invalid`: `form-control` }`}     />
                 <div className="error ">{errors.passwordConfirmation?.message}</div>
              </div>
                <div className="form-group">
                <label htmlFor="role" >Role</label>
                <select  
                       name="role"
                       value={user.role} 
                       onChange={onChange} 
                       ref={register}
                       className={`${errors.role? `form-control is-invalid`: `form-control` }`}
                       placeholder="Enter role"
                       >
                <option defaultValue="admin">admin</option>
                <option>user</option>
                </select>
                <div className="error ">{errors.role?.message}</div>
                </div>
                
                <button className="btn  btn-primary mt-3" 
                        type="submit">Register</button>
            </form>
            
        </div>
    )
}

export default Register;