import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt,faEdit} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Helmet } from 'react-helmet';

function GestCarousel() {
    const [carousels, setCaroussels] = useState([]);
    const [modal, setModal] = useState(false);
    const [id, setId]= useState("");
    const toggle = (id) => {
        setModal(!modal);
        setId(id);  }
        const closeModal=()=>{
          setModal(!modal);
        }
   useEffect(() => {
    Axios.get("/carousel/get-all")
         .then(res=>setCaroussels(res.data))
         .catch(err=>console.log(err.message))
   }, [])
   const deleteCarousel=async (id)=>{
    await  Axios.delete(`/carousel/delete/${id}`);
    setCaroussels(prevCarousel=>prevCarousel.filter(val=> val._id !== id));
    setModal(false);
   }
    return (
        <div className="container mt-5">
          <Helmet>
          <title>Gestion de Carousel | Vtopo BTP </title>
        </Helmet>
        <div className="col-lg-12 col-md-12 d-flex justify-content-between" style={{backgroundColor:'#000000'}}>
         <h1 className="justify-content-start" style={{color:'#FFFFFF'}}>Ajouter Image carousel</h1>
          <Link to="/add-carousel" style={{color:"inherit", marginTop:"1%"}}><button className="btn btn-primary justify-content-end">Ajouter </button></Link>
         </div>
         <div className="col-lg-12 col-md-12 ">
         <table className="table table-hover">
<thead>
<tr>
<th scope="col">Titre</th>
<th scope="col">Introduction</th>
 <th scope="col">Lien</th>
 <th scope="col">image</th>
 <th scope="col">Actions</th>
  
</tr>
</thead>
<tbody>
 
{
    carousels.map(item=>(<tr key={item._id} >
      <th scope="row">
{item.titre}
</th>
<th scope="row" style={{width:"10%"}}>
{item.intro}
</th>
<th scope="row" style={{width:"10%"}}>
{item.lien}
</th>
<td style={{width:"10%"}}><img src={"../../imgs/"+item.img} style={{width:"50px", height:"50px"}} alt="category img"/></td>
<td><button className="btn btn-danger" onClick={(e)=>toggle(item._id)}>Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </button> <Link to={`/edit-carousel/${item._id}`} style={{color:"inherit"}}>  <button className="btn btn-info" >Modifier <FontAwesomeIcon icon={faEdit} style={{color:"#fff"}}/> </button></Link> </td> 
</tr>))}
<Modal isOpen={modal} id={id} toggle={(e)=>closeModal}>
        <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
        <ModalBody>
        
L'image Sera Supprimée Définitivement
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={(e)=>deleteCarousel(id)}>Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </Button>
          <Button color="secondary" onClick={(e)=>closeModal()}>Annuler</Button>
        </ModalFooter>
      </Modal>


</tbody>
</table>
         </div>
   </div>
    )
}

export default GestCarousel
