import React from "react";
import { HashLink as Link } from "react-router-hash-link";

function ContactSection() {
  return (
    <div
      className="icons  py-5 mt-5 "
      style={{
        backgroundImage: "url(../../imgs/bande-T2-01.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize:"cover"
      }}
    >
      <div className="container">
        <h1 style={{ color: "#FFF" }} className="pt-5" id="icons">
          Votre Situation ?
        </h1>
        <div className="d-flex justify-content-between">
          <div className="justify-content-start">
            <h3 style={{ color: "#FFF" }} className="mt-1" id="icons">
              Pour trouver comment nous pouvons vous aider.
            </h3>
          </div>
          <div className="justify-content-end mt-1">
            <button className="btn btn-btn btn-contact">
              <Link
                smooth
                to="/contact#toppage"
                style={{ color: "#FFF", textDecoration: "none" }}
              >
                Demander une soumission
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="container  ">
        <div className="row  mt-3">
          <div className="col-md-2 col-sm-6 col-6 text-center    ">
            <Link smooth to="/contact#toppage" className="hover-fx">
              <i className="icon-iconnavvends-01 " />
            </Link>
            <h3
              className="mt-2"
              style={{ fontSize: "95%", color: "#FFF", textDecoration: "none" }}
            >
              Je vends ma propriété
            </h3>
          </div>
          <div className="col-md-2 col-sm-6 col-6 text-center  ">
            <Link smooth to="/contact#toppage" className="hover-fx">
              <i className="icon-location" />
            </Link>
            <h3
              className="mt-2"
              style={{ fontSize: "95%", color: "#FFF", textDecoration: "none" }}
            >
              Je veux connaître mes limites de propriété
            </h3>
          </div>
          <div
            className="col-md-2 col-sm-6 col-6 text-center text-icons "
            id="custom-icon"
          >
            <Link smooth to="/contact#toppage" className="hover-fx">
              <i className="icon-graphic-tools " />
            </Link>
            <h3
              className="mt-2"
              style={{ fontSize: "95%", color: "#FFF", textDecoration: "none" }}
            >
              J'agrandis ma maison
            </h3>
          </div>
          <div
            className="col-md-2 col-sm-6 col-6 text-center text-icons  "
            id="custom-icon"
          >
            <Link smooth to="/contact#toppage" className="hover-fx">
              <i className="icon-iconnavconstruire-01 " />
            </Link>
            <h3
              className="mt-2"
              style={{ fontSize: "95%", color: "#FFF", textDecoration: "none" }}
            >
              J'ai acheté un terrain et je veux construire
            </h3>
          </div>
          <div
            className="col-md-2 col-sm-6 col-6 text-center text-icons   "
            id="custom-icon"
          >
            <Link smooth to="/contact#toppage" className="hover-fx">
              <i className="icon-iconnavterrain7-01" />
            </Link>
            <h3
              className="mt-2"
              style={{ fontSize: "95%", color: "#FFF", textDecoration: "none" }}
            >
              Je veux diviser mon terrain
            </h3>
          </div>
          <div
            className="col-md-2 col-sm-6 col-6 text-center text-icons  "
            id="custom-icon"
          >
            <Link smooth to="/contact#toppage" className="hover-fx">
              <i className="icon-iconnavcopropriete2-01 " />
            </Link>
            <h3
              className="mt-2"
              style={{ fontSize: "95%", color: "#FFF", textDecoration: "none" }}
            >
              Je veux créer une copropriété
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
