import React,{ useState} from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import Message from "../../Message";
import Progress from "../../Progress";
import { useHistory } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import Axios from "axios";
import moment from 'moment';

 const fieldsSchema = yup.object().shape({
    nameProject : yup
    .string()
    .required("ce champ est obligatoire"),
    descriptionProject : yup
    .string()
    .required("ce champ est obligatoire"),
    date :yup
    .date()
    .required('Required field'),
    descriptionProject :yup
    .string()
    .required("ce champ est obligatoire"),
    client : yup
    .string(),
    imgProject: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
    return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
      }),
      


});

function AddProject() {
    const history = useHistory();
    const [message, setMessage] = useState(null);
    const [uploadPercentage, setUploadPercentage] = useState(0);
   const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(fieldsSchema),
         });
    
   
     const onSubmit = async (data,e) =>{
       e.preventDefault();
       const newProject = new FormData()
       newProject.append('imgProject', data.imgProject[0]);
       newProject.append('nameProject',data.nameProject);
       newProject.append('descriptionProject',data.descriptionProject);
       newProject.append("date",data.date)
       newProject.append("client",data.client);
       newProject.append("place",data.place);
       
        try {
           await Axios.post('/project/add-project', newProject, {
               headers: {
               'Content-Type': 'multipart/form-data'
               },
               onUploadProgress: progressEvent => {
              setUploadPercentage(
              parseInt(
               Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
              );}
              }).then(res=> {
                   const { message } = res.data;
                    setMessage(message);
                    if(!message.msgError){
                         setTimeout(()=>{
                        history.push('/gest-project');
                        },2000)}
                        }).catch(err=>{
              console.log(err.message)
               });
       } catch (error) {
           console.log(error.message)
       }

     } ;
    
      
    return (
        <div className="container mt-5">
              
        <div className="col-md-6 col-lg-6">
            <h1>Ajouter Projet </h1><br/>
            {message ? <Message message={message}/> : null}
        <form  onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
        
            <div className="form-group">
           
                <label htmlFor="name">Nom du Projet</label>
                 <input type="text"  className={`${errors.nameProject? `form-control is-invalid`: `form-control` }`} id="name" name="nameProject" aria-describedby="emailHelp" ref={register}  />
                 <div className="error">{errors.nameProject?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Description</label>
                 <textarea type="text" className={`${errors.descriptionProject? `form-control is-invalid`: `form-control` }`} id="name" name="descriptionProject" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.descriptionProject?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">client</label>
                 <input type="text" className={`${errors.client? `form-control is-invalid`: `form-control` }`} id="name" name="client" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.client?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Lieu du Projet</label>
                 <input type="text" className={`${errors.place? `form-control is-invalid`: `form-control` }`} id="name" name="place" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.place?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Date de Projet</label>
                 <input type="date" data-date-format="mm/dd/yyyy" className={`${errors.date? `form-control is-invalid`: `form-control` }`} id="name" name="date" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.date?.message}</div>
              </div>
             
              
              <div className='form-group mb-4'>
            <label htmlFor="img">Image</label> 
  <input
   type='file'
   className={`${errors.imgProject? `form-control is-invalid`: `form-control` }`}
   id='customFile'
   ref={register} 
   name="imgProject"
 />
  <div className="error ">{errors.imgProject?.message}</div>
 </div>
 <Progress percentage={uploadPercentage} />
 
           
           <br/>
           <button type="submit" className="btn btn-primary"  >Submit</button>
       </form>
       </div>
       </div>
    )
}

export default AddProject
