import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

function Categories() {
    const [categories, setCategories]=useState([]);
    useEffect(() => {
        Axios.get("/category/get-all")
             .then(res=>setCategories(res.data))
             .catch(err=>console.log(err.message))
     }, [])
    return (
        <div className="container mt-5 "   >
            <h1 className="text-center mt-3 mb-5">Vente</h1>
            <div className="row  pt-3">
                {categories.map(item=>(
                <div className="col-md-3 col-lg-3 mb-5" key={item._id}>
                <Link to={`/vente/${item.catName}/${item._id}`}><div><img src={`../../imgs/${item.img}`} style={{width:"100%",height:"100%"}}/></div></Link>
                <Link style={{color:"inherit"}} to={`/vente/${item.catName}/${item._id}`}  ><h3>{item.catName}</h3></Link> 
                <p className=" " style={{whiteSpace:"unset"}}>{item.intro} </p>
                <Link style={{color:"inherit"}} to={`/vente/${item.catName}/${item._id}`}>Voir Plus <FontAwesomeIcon icon={faArrowRight} style={{color:"#03985E"}}/> </Link>
                 </div> ))}
                
            </div>
        </div>
    )
}

export default Categories
