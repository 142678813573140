import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import { FormCat } from './FormCat';
import Loading from "../../Loading";



function EditCat({match}) {
     
     
    const [cat,setCat]=useState(null)
    
    useEffect(() => {
    Axios.get(`/category/get-one/${match.params.id}`)
                .then((result)=>setCat(result.data))
                .catch((error)=>console.log(error.message));
            }, [match.params.id])
   
        return  cat ? <FormCat preloadedValues={cat} match={match.params.id}/> :  <Loading/> 

    
}

export default EditCat