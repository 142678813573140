import React from 'react'
import { HashLink as Link } from "react-router-hash-link";
function Project() {
    return (
        <div  >
        <div style={{backgroundImage:"url(../../imgs/bg-demande-soumission.jpg)", height:'300px',backgroundSize:"cover" }} >
            <div className="text-center mt-5" style={{paddingTop:"100px"}}>
                <h1>Vous avez un projet?</h1>
                <button className="btn btn-btn-green btn-lg "><Link smooth to="/contact#toppage" style={{color:"#FFF",textDecoration:"none"}}>Contactez notre équipe</Link></button>
            </div>
        </div>
        </div>
    )
}

export default Project
