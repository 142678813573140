import React, { useState } from 'react';
import { Alert } from 'reactstrap';

const Message = props=>{
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);
    return(
        
            
            <Alert color={props.message.msgError ? "danger" : "success"} isOpen={visible} toggle={onDismiss} className="container text-center">
            {props.message.msgBody}
              </Alert>
        
    )
}

export default Message;