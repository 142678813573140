import React,{useState,useEffect}from 'react';
import Axios from "axios";
import AllServices from './AllServices';
import { Helmet } from 'react-helmet'
import Project from "../../Project";
function Service() {
    const [services, setServices]= useState([]);
    const [characteristics, setCharacteristics] = useState([])
    useEffect(() => {
        Axios.get("/service/get-first-one")
            .then(res=>{
                setServices(res.data);
                 setCharacteristics(res.data.characteristics)
                })
            .catch(err=>console.log(err.message))
        }, [])
        

    return (
       <> <div className="container" id="toppage">
            <Helmet>
          <title>Services | Vtopo BTP </title>
        </Helmet>
           <><div className="service-page-banner mt-md-5 pt-5">
               <img src={`../../imgs/${services.banner}`} className="img-fluid" style={{  width:"100%"}} id="banner-service"/></div>
            <div className="row mt-3">
            <AllServices/>
  <div className="col-md-8">
    <div className="mycontent-right">
        <h1>{services.name}</h1>
         
        <p style={{whiteSpace:"pre-line"}}>{services.description} </p>
        <ul style={{listStyle:"none"}}>
            {
                characteristics.map((item,index)=>(<li key={index}>{item}</li>))
            }
        </ul>
    </div>
  </div>
</div></> 
        </div>
            <Project/>
        </>
    )
}

export default Service
