import React,{useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import Message from "../../Message";
const schema = yup.object().shape({
    username:yup
    .string()
    .required("ce champ est obligatoire"),
    role:yup
    .string()
    .required("ce champ est obligatoire")
    
  });

export  function FormUser({preloadedValues, match}) {
    const [message, setMessage] = useState(null);
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      
      const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedUser = new FormData()
        updatedUser.append("username",data.username)
        updatedUser.append("role",data.role)
         await Axios.put(`/user/edit-user/${match}`,updatedUser)
        .then((res)=>{
            const { message } = res.data;
            console.log(message)
            setMessage(message);
            if(!message.msgError){
                 setTimeout(()=>{
                history.push('/gest-users');
                },2000)}
            })
        .catch(err=>console.log("err: "+err))
        };
    return (
        <div className="container">
             <h3>Modifier Utilisateur</h3>
            {message ? <Message message={message}/> : null}
            <form onSubmit={handleSubmit(onSubmit)}  >
               <div className="form-group">
                   <label htmlFor="name"  >Nom ou E-mail</label>
                <input type="text" 
                       name="username"
                       ref={register}
                       className={`${errors.username? `form-control is-invalid`: `form-control` }`}
                       placeholder="Enter Username"/>
                       <div className="error ">{errors.username?.message}</div>
                       </div>
                <div className="form-group">
                <label htmlFor="role" >Role </label>
                <select  
                       name="role"
                       ref={register}
                       className={`${errors.role? `form-control is-invalid`: `form-control` }`}
                       placeholder="Enter role"
                       >
                <option >admin</option>
                <option>user</option>
                </select>
                <div className="error ">{errors.role?.message}</div>
                </div>
                
                <button className="btn  btn-primary mt-3" 
                        type="submit">Modifier</button>
            </form>
            
        </div>
    )
}
