import React, { useContext } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./Components/Login";
import Register from "./Components/Register";
import Home from "./Components/Home";
import PrivateRoute from "./hocs/PrivateRoute";
import UnPrivateRoute from "./hocs/UnPrivateRoute";
import GestCategory from "./Components/Auth/Category/GestCategory";
import AddCategory from "./Components/Auth/Category/AddCategory";
import EditCat from "./Components/Auth/Category/EditCat";
import Navigation from "./Components/Navigation";
import GestProduct from "./Components/Auth/Products/GestProduct";
import GestService from "./Components/Auth/Service/GestService";
import GestCarousel from "./Components/Auth/Carousel/GestCarousel";
import GestUsers from "./Components/Auth/Users/GestUsers";
import AddProduct from "./Components/Auth/Products/AddProduct";
import EditProduct from "./Components/Auth/Products/EditProduct";
import AddService from "./Components/Auth/Service/AddService";
import EditService from "./Components/Auth/Service/EditService";
import AddCarousel from "./Components/Auth/Carousel/AddCarousel";
import EditCarousel from "./Components/Auth/Carousel/EditCarousel";
import EditUser from "./Components/Auth/Users/EditUser";
import GestImg from "./Components/Auth/image/GestImg";
import AddImage from "./Components/Auth/image/AddImage";
import Footer from "./Components/Footer";
import Service from "./Components/Pages/Service Page/Service";
import ServiceDetail from "./Components/Pages/Service Page/ServiceDetail";
import "./fontello-f624f3d7/css/fontello.css";
import GestProject from "./Components/Auth/project/GestProject";
import AddProject from "./Components/Auth/project/AddProject";
import EditProject from "./Components/Auth/project/EditProject";
import GestContact from "./Components/Auth/Contact/GestContact";
import EditContact from "./Components/Auth/Contact/EditContact";
import ContactNous from "./Components/Pages/contact page/ContactNous";
import Vents from "./Components/Pages/vent page/Vents";
import ProductDetail from "./Components/Pages/vent page/ProductDetail";
import NosProjet from "./Components/Pages/projet Page/NosProjet";
import Entreprise from "./Components/Pages/Entreprise Page/Entreprise";
import SavPage from "./Components/Pages/sav page/SavPage";
import Profile from "./Components/Auth/Users/Profile";
import Notmatch from "./Components/Notmatch";
import ForgetPassword from "./Components/ForgetPassword";
import {AuthContext} from "./Context/AuthContext"
import NavigationAuth from "./Components/Auth/NavigationAuth";
function App() {
  const { isAuthenticated } = useContext(
    AuthContext
  );
  return (
    <Router>
      {isAuthenticated ? <NavigationAuth/> :<Navigation />}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/entreprise" component={Entreprise} />
        <Route path="/services" exact component={Service} />
        <Route path="/services/:name/:id" component={ServiceDetail} />
        <Route path="/sav" component={SavPage} />
        <Route path="/contact" component={ContactNous} />
        <Route path="/vente" exact component={Vents} />
        <Route path="/nos-projets" component={NosProjet} />
        <Route path="/vente/:name/:id" component={ProductDetail} />
        <UnPrivateRoute
          path="/vtopo/UF3saGVSwTtVNmKb"
          exact
          component={Login}
        />
        <UnPrivateRoute
          path="/vtopo/UF3saGVSwTtVNmKb/mot-de-passe-oublié"
          component={ForgetPassword}
        />
        <PrivateRoute roles={["admin"]} path="/add-user" component={Register} />
        <PrivateRoute
          roles={["admin"]}
          path="/gest-users"
          component={GestUsers}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/gest-category"
          component={GestCategory}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/add-cat"
          component={AddCategory}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/edit-category/:id"
          component={EditCat}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/gest-products"
          component={GestProduct}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/gest-service"
          component={GestService}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/gest-carousel"
          component={GestCarousel}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/add-product"
          component={AddProduct}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/edit-product/:id"
          component={EditProduct}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/add-service"
          component={AddService}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/edit-service/:id"
          component={EditService}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/add-carousel"
          component={AddCarousel}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/edit-carousel/:id"
          component={EditCarousel}
        />
        <PrivateRoute
          roles={["admin"]}
          path="/edit-user/:id"
          component={EditUser}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/gest-pictures"
          component={GestImg}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/add-picture"
          component={AddImage}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/gest-project"
          component={GestProject}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/add-project"
          component={AddProject}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/edit-project/:id"
          component={EditProject}
        />
        <PrivateRoute 
        roles={["admin", "user"]}
        path="/gest-contact"
        component={GestContact}
        />
        <PrivateRoute 
        roles={["admin", "user"]}
        path="/edit-contact/:id"
        component={EditContact}
        />
        <PrivateRoute
          roles={["admin", "user"]}
          path="/profile/:username"
          component={Profile}
        />
        <Route path="*">
          <Notmatch />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
