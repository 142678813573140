import React,{useState,useEffect}from 'react';
import Axios from "axios";
import AllServices from "./AllServices";
import { Helmet } from 'react-helmet'
import Project from "../../Project";
function ServiceDetail({match}) {
    const [service, setService] = useState([]);
    const [characteristics, setCharacteristics] = useState([])
    useEffect(() => {
        Axios.get(`/service/get-one/${match.params.id}`)
             .then(res=>{
                 setService(res.data);
                 setCharacteristics(res.data.characteristics)})
             .catch(error=>console.log(error.message))
    }, [match.params.id])
    return (
        <><div className="container"  id="toppage">
        <Helmet>
          <title>{match.params.name} | Vtopo BTP </title>
        </Helmet>
        <><div className="service-page-banner mt-md-5 pt-5"><img src={`../../imgs/${service.banner}`} id="banner-service" className="img-fluid" style={{   width:"100%"}}/></div>
         <div className="row mt-3">
         <AllServices/>
<div className="col-md-8 mt-3">
 <div className="mycontent-right">
     <h1>{service.name}</h1>
      
     <p style={{whiteSpace:"pre-line"}}>{service.description} </p>
     <ul style={{listStyle:"none"}}>
            {
                characteristics.map((item,index)=>(<li key={index}>{item}</li>))
            }
        </ul>
 </div>
</div>
</div></> 
 
     </div>
     <Project/>
     </>
    )
}

export default ServiceDetail
