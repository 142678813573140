import React from 'react';
import CarouselHome from './CarouselHome';
import Categories from './Categories';
import Company from './Company';
import ContactSection from './ContactSection';
import Project from './Project';
import Sav from './Sav';
import ServiceSection from './ServiceSection';
import { Helmet } from 'react-helmet'


const Home = ()=>(
    <div id="toppage">
        <Helmet>
          <title>Accueil | Vtopo BTP </title>
        </Helmet>
    <CarouselHome/>
    <Company/>
    <ServiceSection/>
    <ContactSection/>
    <Categories/>
    <Sav/>
    <Project/>
    </div>
)

export default Home;