import React,{useState, useEffect} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Axios from "axios";
 
function CarouselHome() {
  const [carousels, setCaroussels] = useState([]);
  useEffect(() => {
     Axios.get("/carousel/get-all")
          .then(res=>setCaroussels(res.data))
          .catch(err=>console.log(err.message))
    }, [])
    return (
   
     <Carousel  className=""  id="toppage" >
 { carousels.map(item=>(
 <Carousel.Item key={item._id} id="carousel" >
  <img
      className="d-block w-100 "
      id="carouselImg"
      src={`../../imgs/${item.img}`}
      alt="slides"
       
    />
   
    <Carousel.Caption className="text-left text-carousel   "  id="caption">
     <div className="ml-5">
     <h3  >{item.titre}</h3>
      <p  >{item.intro}</p>
      <p id="button-carousel"><a className="btn btn-btn-green" href={item.lien} role="button">Voir Plus</a></p>
     </div>
    </Carousel.Caption>
  </Carousel.Item>))}
   
  
</Carousel>
 
    )
}

export default CarouselHome
