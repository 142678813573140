import React,{ useState} from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import Message from "../../Message";
import Progress from "../../Progress";
import { useHistory } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import Axios from "axios";
const formSchema = {
    name: yup.string().required("ce champ est obligatoire")
  };

const fieldsSchema = yup.object().shape({
    characteristics: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required("ce champ est obligatoire")
    .min(1,"ce champ est obligatoire"),
    name : yup
    .string()
    .required("ce champ est obligatoire"),
    intro : yup
    .string()
    .required("ce champ est obligatoire"),
    img: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
    return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
      }),
    banner: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
    return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
      }),


});
function AddService() {
    const history = useHistory();
     const [message, setMessage] = useState(null);
     const [uploadPercentage, setUploadPercentage] = useState(0);
    const { register, control, handleSubmit, formState, errors } = useForm({
        defaultValues: {
            characteristics: [{ name: "Caractéristiques" }]
          },
          resolver: yupResolver(fieldsSchema),
          mode: "onChange"});
      const { fields, append, remove } = useFieldArray(
        {
          control,
          name: "characteristics"
        }
      );
    
      const onSubmit = async (data,e) =>{
        e.preventDefault();
        const newService = new FormData()
        newService.append('img', data.img[0]);
        newService.append('banner', data.banner[0]);
        newService.append('name',data.name);
        newService.append('intro',data.intro);
        newService.append('hidden',data.hidden);
        newService.append('description',data.description);
        for (let i = 0; i < data.characteristics.length; i++) {
            newService.append("characteristics", data.characteristics[i].name)
           } 
        try {
            await Axios.post('/service/add-service', newService, {
                headers: {
                'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
               setUploadPercentage(
               parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
               )
               );}
               }).then(res=> {
                    const { message } = res.data;
                     setMessage(message);
                     if(!message.msgError){
                          setTimeout(()=>{
                         history.push('/gest-service');
                         },2000)}
                         }).catch(err=>{
               console.log(err.message)
                });
        } catch (error) {
            console.log(error.message)
        }

      } ;
    return (
        <div className="container mt-5">
              
        <div className="col-md-6 col-lg-6">
            <h1>Ajouter Service </h1>
            <br/>
            {message ? <Message message={message}/> : null}
        <form  onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
            <div className="form-group">
           
                <label htmlFor="name">Nom du Service</label>
                 <input type="text"  className={`${errors.name? `form-control is-invalid`: `form-control` }`} id="name" name="name" aria-describedby="emailHelp" ref={register}  />
                 <div className="error">{errors.name?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Intro</label>
                 <textarea type="text" className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.intro?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Description</label>
                 <textarea type="text" className={`${errors.description? `form-control is-invalid`: `form-control` }`} id="name" name="description" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.description?.message}</div>
              </div>
              <div className="form-group">
                <label>Page Accueil </label><br/>
                
                <select className={`${errors.hidden? `form-control is-invalid`: `form-control` }`} name="hidden" ref={register}  >
                    <option value="false">visible</option>
                    <option value="true">caché</option>
                  </select>
 
            </div>   
              <div className='form-group mb-4'>
            <label htmlFor="img">Image</label> 
  <input
   type='file'
   className={`${errors.img? `form-control is-invalid`: `form-control` }`}
   id='customFile'
   ref={register} 
   name="img"
 />
  <div className="error ">{errors.img?.message}</div>
 </div>
 <div className='form-group mb-4'>
            <label htmlFor="img">Image De Couverture</label> 
  <input
   type='file'
   className={`${errors.banner? `form-control is-invalid`: `form-control` }`}
   id='customFile'
   ref={register} 
   name="banner"
 />
  <div className="error ">{errors.banner?.message}</div>
 </div>
 <div className="mb-4 mt-4">
     <label>Caractéristiques de Service</label> 
     <button
type="button"  className="btn btn-primary ml-3"
onClick={() => {
append({ name: "" });
 }}
>
Ajouter
</button>
         
<ul className="mb-4 mt-4" style={{listStyle:"none"}}>
{fields.map((item, index) => {
 return (
    <div className="row mt-3">
     <div className="col-md-6">
<li key={item.id}>
<input
name={`characteristics[${index}].name`}
 defaultValue={`${item.name}`}
 ref={register({})}
 className={`${errors.characteristics? `form-control is-invalid` : `form-control ` }`}
 required
/>
<div className="error">{errors.characteristics?.message}</div>
</li>

</div>
<div className="col-md-6">
<button type="button" className="btn btn-danger" onClick={() => remove(index)}>
Supprimer
</button>
</div>
</div>
);
 })}
</ul>
</div>


              
              
             

 
 
 <Progress percentage={uploadPercentage} />
 
           
           <br/>
           <button type="submit" className="btn btn-primary"  >Submit</button>
       </form>
       </div>
       </div>
    )
}

export default AddService
