import React, {Fragment} from 'react'
import Company from "../../Company";
import { Helmet } from 'react-helmet'
import Project from "../../Project";
import {ReactComponent as Logo} from "../../icons/loading-logo.svg";
function Entreprise() {
    return (
     <Fragment>  
      <div className="container mt-5 pt-5" id="toppage">
             <Helmet>
          <title>Entreprise | Vtopo BTP </title>
        </Helmet>
            <div className="row mt-5 mb-5">
                <div className="col-md-12 col-lg-6 col-12 text-center ">
                <Logo id="logo-company" style={{width :"70%"}}/>
               
                </div>
                <div className="col-md-12 col-lg-6 col-12">
                    <h6 className="text-center">Vétérans de la Topographie du Bâtiment et des Travaux Publics</h6>
                    <p className="text-left">
                    VTOPO BTP SOCIÉTÉ DE CONSEIL ET DE PRESTATIONS DE LEVÉS TOPOGRAPHIQUES, GÉOMATIQUES ,MODÉLISATION 3D, TRAVAUX BTP , FONCIERS  ET ÉTUDES D'AMÉNAGEMENT .
<br/>Nous offrons à nos clients notre savoir-faire, nos compétences ainsi que nos moyens techniques. En choisissant VTOPO BTP , On gère toutes les phases inhérentes à la réalisation de vos projets, des études préliminaires jusqu’à la livraison des travaux.
<br/>VTOPO BTP se spécialise aussi  dans la distribution d’instruments reliés à la Topographie et à la Construction, De cette manière, VTOPO BTP est en mesure de proposer à ses clients les solutions les plus évoluées provenant des avancées technologiques avec les meilleures marques sur le marché.
Forte d’un atelier S.A.V d’entretien et de réparation certifié, d’une équipe technique terrain et d’un service qui répond à tous vos besoins. 

                    </p>
                </div>
            
            </div>
            
        </div>
         <Project/>
         </Fragment>
    )
}

export default Entreprise
