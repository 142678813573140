import React,{useState, useEffect} from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import Message from "../../Message";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

const formSchema = {
  name: yup.string().required("ce champ est obligatoire")
};

const fieldsSchema = yup.object().shape({
  characteristics: yup
  .array()
  .of(yup.object().shape(formSchema))
  .required("ce champ est obligatoire")
  .min(1,"ce champ est obligatoire"),
  nameProduct : yup
  .string()
  .required("ce champ est obligatoire"),
  introProduct : yup
  .string()
  .required("ce champ est obligatoire"),
  _id : yup
  .string()
  .required("ce champ est obligatoire"),
  


});

export function FormProduct({preloadedValues, match}) {
    const [message, setMessage] = useState(null);
    const history = useHistory();
    const { register, control, handleSubmit, formState, errors } = useForm({
        defaultValues: preloadedValues,
          resolver: yupResolver(fieldsSchema),
          mode: "onChange"
        });
      const { fields, append, remove } = useFieldArray(
        {
          control,
          name: "characteristics"
        }
      );
      const [categories, setCategories]=useState([]);
useEffect(() => {
       Axios.get("/category/get-all")
            .then(res=>setCategories(res.data))
            .catch(err=>console.log(err.message))
    }, [])
  const [product, setProduct]=useState({})
  useEffect(() => {
    Axios.get(`/category/get-product/${match}`)
         .then(res=>setProduct(res.data[0]))
         .catch(err=>console.log(err.message))
  }, [])
  const onChange = (e) => {
    setProduct({...product, [e.target.name]: e.target.value});
  }
   
      
      const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedProduct = new FormData()
        updatedProduct.append('imgProduct', data.photo[0])
        updatedProduct.append('ficheTechnique', data.fiche[0])
        updatedProduct.append("nameProduct",data.nameProduct)
        updatedProduct.append("introProduct",data.introProduct)
        updatedProduct.append("categoeryID",data._id)
         for (let i = 0; i < data.characteristics.length; i++) {
           updatedProduct.append("characteristics", data.characteristics[i].name)
           }
          await Axios.put(`/product/edit-product/${match}`,updatedProduct)
        .then((res)=>{
            const { message } = res.data;
            setMessage(message);
            if(!message.msgError){
                 setTimeout(()=>{
                history.push('/gest-products');
                },2000)}
            })
        .catch(err=>console.log(err.message))
        };
    return (
        <div className="container mt-5">
             <div className="row row-a">
                 <div className="col-md-10 col-lg-10">
                     <h1>Modifier Produit </h1><br/>
                     {message ? <Message message={message}/> : null}
                  <form   onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data"  >
                  <div className="form-group ">
                   
                   <label htmlFor="name"> Catégorie</label>
                   <select className={`${errors.cat? `form-control is-invalid`: `form-control` }`} value={product._id} onChange={onChange} name="_id" ref={register}  >
                       {
                           categories.map(item=>(
                               <option key={item._id} value={item._id}>{item.catName} </option>
                           ))
                       }
                        
                   </select>
                   <div className="error ">{errors.cat?.message}</div>
               </div>
                     <div className="form-group">
                         <label htmlFor="name">Nom du Produit</label>
                          <input type="text" className={`${errors.nameProduct? `form-control is-invalid`: `form-control` }`} id="name" name="nameProduct" ref={register}/>
                          <div className="error ">{errors.nameProduct?.message}</div>
                       </div>
                       <div className="form-group">
                         <label htmlFor="name">intro</label>
                          <textarea className={`${errors.introProduct? `form-control is-invalid`: `form-control` }`} id="name" name="introProduct"  ref={register}  />
                          <div className="error ">{errors.introProduct?.message}</div>
                       </div> 
                       <div className="mb-4 mt-4">
     <label>modifie une Caractéristique </label> 
     <button
type="button"  className="btn btn-primary ml-3"
onClick={() => {
append({ name: "" });
 }}
>
Ajouter
</button>
         
<ul className="mb-4 mt-4" style={{listStyle:"none"}}>
{fields.map((item, index) => {
 return (
    <div className="row mt-3">
     <div className="col-md-6">
<li key={item.id}>
   
<input
name={`characteristics[${index}].name`}
 defaultValue={`${preloadedValues.characteristics[index]}`}
 ref={register({})}
 className="form-control"
 
/>
</li>

</div>
<div className="col-md-6">
<button type="button" className="btn btn-danger" onClick={() => remove(index)}>
Supprimer
</button>
</div>
</div>
);
 })}
</ul>
</div>
                        <div className='custom-file mb-4 col-md-6 col-lg-6'>
                          <label>Image</label>
                        <input
                             type='file'
                             className={`${errors.photo? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="photo"
                             ref={register}
                              />
                              <div className="error ">{errors.photo?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.imgProduct}`} style={{width:'40%',height:"40%"}} alt="" />
           </div>
         
         
        </div>
        <div className='custom-file mb-4 col-md-6 col-lg-6'>
                          <label>Fiche Technique</label>
                        <input
                             type='file'
                             className={`${errors.fiche? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="fiche"
                             ref={register}
                              />
                              <div className="error ">{errors.fiche?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right'  >
                       <div >
                          
                         <a href={`../../files/${preloadedValues.ficheTechnique}` } target="blank"><FontAwesomeIcon icon={faFilePdf}  style={{width:'10%',height:"10%",color:"#FF0000"}} /></a>
                      
                         </div>
         
         
        </div>
         <button type="submit" className="btn btn-primary mt-3"  >Submit</button>
                </form>
                </div>
                </div>
                </div>
    )
}

 
