import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import { FormContact } from './FormContact';
import Loading from "../../Loading";

function EditContact({match}) {
    const [contact,setContact]=useState(null)
    
    useEffect(() => {
    Axios.get(`/contact/get-one/${match.params.id}`)
                .then((result)=>setContact(result.data))
                .catch((error)=>console.log(error.message));
            }, [match.params.id])
   
        return  contact ? <FormContact preloadedValues={contact} match={match.params.id}/> :  <Loading/> 
}

export default EditContact
