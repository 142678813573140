import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import Loading from "../../Loading";
import { FormService } from "./FormService";

function EditService({match}) {
    const [service,setService]=useState(null)
    
    useEffect(() => {
    Axios.get(`/service/get-one/${match.params.id}`)
                .then((result)=>setService(result.data))
                .catch((error)=>console.log(error.message));
            }, [match.params.id])
    return service ? <FormService preloadedValues={service} match={match.params.id}/> : <Loading/>
}

export default EditService
