import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import Loading from "../../Loading";
import {FormCarousel} from "./FormCarousel";

function EditCarousel({match}) {
    
    const [carousel,setCarousel]=useState(null)
    
    useEffect(() => {
    Axios.get(`/carousel/get-one/${match.params.id}`)
                .then((result)=>setCarousel(result.data))
                .catch((error)=>console.log(error.message));
            }, [match.params.id])
    
            return carousel ? <FormCarousel preloadedValues={carousel} match={match.params.id}/> : <Loading/>
        
    
}

export default EditCarousel
