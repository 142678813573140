import React,{useState,useEffect} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import Message from "../../Message";
const schema = yup.object().shape({
     
    lien:yup
    .string()
    .required("ce champ est obligatoire"),
    intro:yup
    .string(75, "ce champ ne doit pas passer 75 caractères"),
    titre:yup
    .string(25, "ce champ ne doit pas passer 25 caractères")
    
  });
export  function FormCarousel({preloadedValues, match}) {
    const [message, setMessage] = useState(null);
    const history = useHistory();
    const [products, setProducts] =useState([])

    useEffect(() => {
            Axios.get("/category/get-all")
                 .then(res=>setProducts(res.data))
                 .catch(err=>console.log(err.message))
          }, [])
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      const [lien, setLien]=useState({
        lien:"",
    })
    useEffect(() => {
        Axios.get(`/carousel/get-one/${match}`)
        .then(res=>setLien(res.data))
        .catch((err)=>console.log(err))
    }, [])
   const onChange = (e) => {
       setLien({...lien, [e.target.name]: e.target.value});
     }
      const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedCarousel = new FormData()
        updatedCarousel.append('img', data.photo[0])
        updatedCarousel.append("lien",data.lien)
        updatedCarousel.append("intro",data.intro)
        updatedCarousel.append("titre",data.titre)
         await Axios.put(`/carousel/edit-carousel/${match}`,updatedCarousel)
        .then((res)=>{
            const { message } = res.data;
            setMessage(message);
            if(!message.msgError){
                 setTimeout(()=>{
                history.push('/gest-carousel');
                },2000)}
            })
        .catch(err=>console.log(err.message))};
    return (
        <div className="container mt-5">
        <div className="row row-a">
            <div className="col-md-10 col-lg-10">
                <h1>Modifier Catégorie </h1><br/>
                {message ? <Message message={message}/> : null}
             <form   onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data"  >
                 
                <div className="form-group">
                    <label htmlFor="name">Titre</label>
                     <input type="text" className={`${errors.titre? `form-control is-invalid`: `form-control` }`} id="name" name="titre" ref={register}  />
                     <div className="error ">{errors.titre?.message}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">intro</label>
                     <textarea className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro"  ref={register}  />
                     <div className="error ">{errors.intro?.message}</div>
                  </div>
                  <div className="form-group">
                <label htmlFor="lien">Lien</label>
              <select className="custom-select"  name="lien" value={lien.lien} onChange={onChange}   ref={register}>
               
                <option value="/nos-projets">Page Nos Projets</option>
                      <option value="/vente">Page Vente</option>
                      <option value="/services">Page Services</option>
                      <option value="/sav">Page SAV</option>
                      <option value="/contact">Page Contact</option>
                      {
                        products.map(item=><option key={item._id} value={`/vente/${item.catName}/${item._id}`} >Page {item.catName}</option>)
                      }
              </select>
              </div>
                   <div className='custom-file mb-4 col-md-6 col-lg-6'>
                     <label>Image</label>
                   <input
                        type='file'
                        className={`${errors.photo? `form-control is-invalid`: `form-control` }`}
                        id='customFile'
                        name="photo"
                        ref={register}
                         />
                         <div className="error ">{errors.photo?.message}</div>
                          </div>
   <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
      <div>
          <img src={`../../imgs/${preloadedValues.img}`} style={{width:'40%',height:"40%"}} alt="" name="imgPath" ref={register}/>
      </div>
    
    
   </div>
    <button type="submit" className="btn btn-primary"  >Submit</button>
           </form>
           </div>
           </div>
           </div>
    )
}
