import React from 'react';
import { Helmet } from 'react-helmet';
function Notmatch() {
    return (
        <div className="container mt-5 pt-5  "  style={{backgroundColor:"black",minWidth:"100%"}}   id="toppage">
            <Helmet>
          <title>Vtopo BTP </title>
        </Helmet>
            <div className="row mt-5 "  >
           
            <section className='content'>
		  <div className='planet'></div>
  	<div className='astronauta'></div>
  	<div className='text'>
    			<h1>Ooops!</h1>
    		<h2>You’re either misspelling the URL<br/>
or requesting a page that's no longer here.</h2>
<div className="text-center mt-4 mb-5">
	<a className="btn-back" href="/" style={{textDecoration:"none"}}>Back to Home page</a>
</div>
  </div>
</section>
           
          
            
        </div>
        </div>
    )
}

export default Notmatch
