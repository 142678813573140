import React,{useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import Message from "../../Message";

const schema = yup.object().shape({
    
  catName:yup
  .string()
  .required("ce champ est obligatoire"),
  intro:yup
  .string()
  .required("ce champ est obligatoire")
});

export function FormCat({preloadedValues, match}) {
    const [message, setMessage] = useState(null);
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      
      const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedCat = new FormData()
        updatedCat.append('img', data.photo[0])
        updatedCat.append("catName",data.catName)
        updatedCat.append("intro",data.intro)
         await Axios.put(`/category/edit-category/${match}`,updatedCat)
        .then((res)=>{
            const { message } = res.data;
            setMessage(message);
            if(!message.msgError){
                 setTimeout(()=>{
                history.push('/gest-category');
                },2000)}
            })
        .catch(err=>console.log(err.message))
        };
    return (
        <div className="container mt-5">
             <div className="row row-a">
                 <div className="col-md-10 col-lg-10">
                     <h1>Modifier Catégorie </h1><br/>
                     {message ? <Message message={message}/> : null}
                  <form   onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data"  >
                      
                     <div className="form-group">
                         <label htmlFor="name">nom</label>
                          <input type="text" className={`${errors.catName? `form-control is-invalid`: `form-control` }`} id="name" name="catName" ref={register}  />
                          <div className="error ">{errors.catName?.message}</div>
                       </div>
                       <div className="form-group">
                         <label htmlFor="name">intro</label>
                          <textarea className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro"  ref={register}  />
                          <div className="error ">{errors.intro?.message}</div>
                       </div>
                        <div className='custom-file mb-4 col-md-6 col-lg-6'>
                            <label>Image</label>
                        <input
                             type='file'
                             className={`${errors.photo? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="photo"
                             ref={register}
                              />
                              <div className="error ">{errors.photo?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.img}`} style={{width:'40%',height:"40%"}} alt="" name="imgPath" ref={register}/>
           </div>
         
         
        </div>
         <button type="submit" className="btn btn-primary"  >Submit</button>
                </form>
                </div>
                </div>
                </div>
    )
}
