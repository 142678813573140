import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import Loading from "../../Loading";
import  {FormUser} from "./FormUser";

function EditUser({match}) {
    const [user,setUser]=useState(null)
    
    useEffect(() => {
    Axios.get(`/user/get-one/${match.params.id}`)
                .then((result)=>setUser(result.data))
                .catch((error)=>console.log("error :" +error.message));
            }, [match.params.id])
            return user ? <FormUser preloadedValues={user} match={match.params.id}/> : <Loading/>
}

export default EditUser
