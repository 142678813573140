import React,{useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import Message from "../../Message";
const schema = yup.object().shape({
    emailInfo:yup
    .string()
    .email("ce champ doit être de type email")
    .required("ce champ est obligatoire"),
    telInfo:yup
    .string()
    .required("ce champ est obligatoire"),
    emailSav:yup
    .string()
    .email("ce champ doit être de type email")
    .required("ce champ est obligatoire"),
    telSav:yup
    .string()
    .required("ce champ est obligatoire"),
    emailSc:yup
    .string()
    .email("ce champ doit être de type email")
    .required("ce champ est obligatoire"),
    telSc:yup
    .string()
    .required("ce champ est obligatoire")
  });

export  function FormContact({preloadedValues, match}) {
    const [message, setMessage] = useState(null);
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedContact = new FormData() 
        updatedContact.append("emailInfo",data.emailInfo)
        updatedContact.append("telInfo",data.telInfo)
        updatedContact.append("emailSav",data.emailSav)
        updatedContact.append("telSav",data.telSav)
        updatedContact.append("emailSc",data.emailSc)
        updatedContact.append("telSc",data.telSc)
         await Axios.put(`/contact/edit-contact/${match}`,updatedContact)
        .then((res)=>{
            const { message } = res.data;
            setMessage(message);
            if(!message.msgError){
                 setTimeout(()=>{
                history.push('/gest-contact');
                },2000)}
            })
        .catch(err=>console.log(err.message))
        };
    return (
        <div className="container mt-5">
        <div className="row row-a">
            <div className="col-md-10 col-lg-10">
                <h1>Modifier Catégorie </h1><br/>
                {message ? <Message message={message}/> : null}
             <form   onSubmit={handleSubmit(onSubmit)} encType="application/json"  >
                 
                <div className="form-group">
                    <label htmlFor="name">Email de direction</label>
                     <input type="text" className={`${errors.emailInfo? `form-control is-invalid`: `form-control` }`} id="name" name="emailInfo" ref={register}  />
                     <div className="error ">{errors.emailInfo?.message}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Numero de direction</label>
                     <input type="text" className={`${errors.telInfo? `form-control is-invalid`: `form-control` }`} id="name" name="telInfo"  ref={register}  />
                     <div className="error ">{errors.telInfo?.message}</div>
                  </div>
                <div className="form-group">
                    <label htmlFor="name">Email SAV</label>
                     <input type="text" className={`${errors.emailSav? `form-control is-invalid`: `form-control` }`} id="name" name="emailSav" ref={register}  />
                     <div className="error ">{errors.emailSav?.message}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Numero SAV</label>
                     <input type="text" className={`${errors.telSav? `form-control is-invalid`: `form-control` }`} id="name" name="telSav"  ref={register}  />
                     <div className="error ">{errors.telSav?.message}</div>
                  </div>
                <div className="form-group">
                    <label htmlFor="name">Email Service Commercial</label>
                     <input type="text" className={`${errors.emailSc? `form-control is-invalid`: `form-control` }`} id="name" name="emailSc" ref={register}  />
                     <div className="error ">{errors.emailSc?.message}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Numero Service Commercial</label>
                     <input type="text" className={`${errors.telSc? `form-control is-invalid`: `form-control` }`} id="name" name="telSc"  ref={register}  />
                     <div className="error ">{errors.telSc?.message}</div>
                  </div>
                    
    <button type="submit" className="btn btn-primary"  >Submit</button>
           </form>
           </div>
           </div>
           </div>
    )
}
