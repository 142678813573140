import React, { useContext } from "react";
import { AuthContext } from "../Context/AuthContext"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      {" "}
      {isAuthenticated ? null : (
      
        <footer class="bg-dark text-center  ">
         
        <div class="container p-1 pb-0">
         
          <section class="mb-1 social-media">
          
            <a class="btn btn-outline-light btn-floating m-1 rounded-circle" href="https://www.facebook.com/profile.php?id=100057637801377" target="_blank"  >
            <FontAwesomeIcon
              icon={faFacebook}
              id="facebook"
              
            />
              </a>
       
           
            <a class="btn btn-outline-light btn-floating m-1 rounded-circle" href="https://www.instagram.com/vtopobtp/" target="_blank" 
              >
               <FontAwesomeIcon
              icon={faInstagram}
              id="instagram"
              
            />
            </a>
      
           
            <a class="btn btn-outline-light btn-floating m-1 rounded-circle" href="https://www.linkedin.com/in/vtopo-btp-2861a8215/" target="_blank"
              >
              <FontAwesomeIcon
              icon={faLinkedinIn}
              id="linkedin"
              
            />
            </a>
           
        
          </section>
         
        </div>
        
        <div class="text-center p-3 text-white" style={{backgroundColor:"rgba(0, 0, 0, 0.2)"}}>
        Copyright VTOPO BTP © {new Date().getFullYear()}. All rights
          reserved - Propulsé par{" "}
        <a class="text-white" href="https://www.seek4digital.com/" target="_blank">
                Seek4Digital
         </a>
          
        </div>
       
      </footer>
      )}
    </>
  );
}

export default Footer;
