import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Message from "../Components/Message";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const schema = yup.object().shape({
  userName: yup.string().required("ce champ est obligatoire"),
});

function ForgetPassword() {
  const [message, setMessage] = useState(null);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();
  const onSubmit = async (data, e) => {
    e.preventDefault();
    const userName = new FormData();
    userName.append("username", data.userName);

    try {
      await Axios.post("/user/forget-password", userName)
        .then((res) => {
          const { message } = res.data;
          setMessage(message);
          if (!message.msgError) {
            setTimeout(() => {
              history.push({ pathname: "/", hash: "#toppage" });
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      if (err.response.status === 500) {
        setMessage({
          msgBody: "There was a problem with the server",
          msgError: true,
        });
      } else {
        setMessage(err.response.data.msg);
      }
    }
  };
  return (
    <div className="container mt-5 mb-3 ">
      <Helmet>
        <title>Trouvez votre compte | Vtopo BTP </title>
      </Helmet>
      <div className="row">
        <div className="col-lg-3 col-md-2"></div>
        <div className="col-lg-6 col-md-8 login-box">
          <div className="col-lg-12">
            <img src="../../imgs/loading-logo.svg" />
          </div>
          <div className="col-lg-12 login-title">Trouvez votre compte</div>

          <div className="col-lg-12 login-form">
            <div className="col-lg-12 login-form">
              {message ? <Message message={message} /> : null}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group-login">
                  <label className="form-control-label-login">USERNAME</label>
                  <input
                    className={`${
                      errors.userName
                        ? `form-control is-invalid`
                        : `form-control`
                    }`}
                    ref={register}
                    type="text"
                    name="userName"
                    id="login-input"
                  />
                </div>

                <div className="col-lg-12 loginbttm-login mb-4">
                  <div className="col-lg-6 login-btm login-button">
                    <button type="submit" className="btn btn-outline-primary">
                      Envoyer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
