import React,{useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import Message from "../../Message";
import moment from 'moment';
const fieldsSchema = yup.object().shape({
    nameProject : yup
    .string()
    .required("ce champ est obligatoire"),
    descriptionProject : yup
    .string()
    .required("ce champ est obligatoire"),
     
    descriptionProject :yup
    .string()
    .required("ce champ est obligatoire"),
    client : yup
    .string(),});
export function FormProject({preloadedValues, match}) {
    const [message, setMessage] = useState(null);
    const history = useHistory();
    const { register,handleSubmit,errors } = useForm({
        resolver: yupResolver(fieldsSchema),
        defaultValues: {
            nameProject :preloadedValues.nameProject,
            descriptionProject: preloadedValues.descriptionProject,
            date :  moment(preloadedValues.date).format('DD-MM-YYYY'),
            client : preloadedValues.client,
            place : preloadedValues.place
        }
    });
    const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedProject = new FormData()
        updatedProject.append('imgProduct', data.photo[0])
        updatedProject.append('nameProject',data.nameProject);
        updatedProject.append('descriptionProject',data.descriptionProject);
        updatedProject.append("date",data.date)
        updatedProject.append("client",data.client);
        updatedProject.append("place",data.place);
           await Axios.put(`/project/edit-project/${match}`,updatedProject)
        .then((res)=>{
            const { message } = res.data;
            setMessage(message);
            if(!message.msgError){
                 setTimeout(()=>{
                history.push('/gest-project');
                },2000)}
            })
        .catch(err=>console.log(err.message))
          };
    
    return (
        <div className="container mt-5">
              
        <div className="col-md-6 col-lg-6">
            <h1>Modifier Projet </h1><br/>
            {message ? <Message message={message}/> : null}
        <form  onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
        
            <div className="form-group">
           
                <label htmlFor="name">Nom du Projet</label>
                 <input type="text"  className={`${errors.nameProject? `form-control is-invalid`: `form-control` }`} id="name" name="nameProject" aria-describedby="emailHelp" ref={register}  />
                 <div className="error">{errors.nameProject?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Description</label>
                 <textarea type="text" className={`${errors.descriptionProject? `form-control is-invalid`: `form-control` }`} id="name" name="descriptionProject" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.descriptionProject?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">client</label>
                 <input type="text" className={`${errors.client? `form-control is-invalid`: `form-control` }`} id="name" name="client" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.client?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Lieu de Projet</label>
                 <input type="text" className={`${errors.place? `form-control is-invalid`: `form-control` }`} id="name" name="place" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.place?.message}</div>
              </div>
              <div className="form-group">
                <label htmlFor="name">Date de Projet</label>
                 <input type="text"  className={`${errors.date? `form-control is-invalid`: `form-control` }`} id="name" name="date" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.date?.message}</div>
              </div>
              <div className='custom-file mb-4 col-md-6 col-lg-6'>
                          <label>Image</label>
                        <input
                             type='file'
                             className={`${errors.photo? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="photo"
                             ref={register}
                              />
                              <div className="error ">{errors.photo?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.imgProject}`} style={{width:'40%',height:"40%"}} alt="" />
           </div>
         
         
        </div>
               <br/>
           <button type="submit" className="btn btn-primary"  >Submit</button>
       </form>
       </div>
       </div>
    )
}
