import React from 'react'

function Sav() {
    return (
        <div className="container mt-5">
           
            <div className="row pt-3" style={{backgroundColor:"#DFE2E5"}}>
            <div className="col-md-6 col-lg-6 col-12">
    
    <h1 className="text-center pt-5">S.A.V</h1>
        <p className=" pt-3 ml-4" >VTOPO BTP offre une large gamme de services techniques ou services après-vente permet à nos techniciens qualifiés de procéder à l’entretien complet et à la réparation des instruments topographiques . <br/>
        Différents niveaux de maintenance sont proposés à la carte, en fonction de chaque instrument et de leur utilisation.</p>
        </div>

  <div className="col-md-6 col-lg-6 col-12  ">
     <figure>
        <img src="../../imgs/SAV-accueil-01.jpg" className="img-sav" style={{width:"100%"}} alt=""/></figure>
     
  </div>
  
  
   
 

 
                 
            </div>
            
        </div>
    )
}

export default Sav
