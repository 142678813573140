import React,{useState,useEffect}from 'react';
import Axios from "axios";
import { NavLink } from "react-router-dom";
import { HashLink  } from "react-router-hash-link"; 

function AllServices() {
    const [names, setNames] = useState([]);
useEffect(() => {
            Axios.get("/service/get-all")
                .then(res=>{
                    setNames(res.data);
                     
                    })
                .catch(err=>console.log(err.message))
            }, [])
    return (
        <div className="col-md-4 mt-3 col-12">
             
        <h1 className="mb-3">  Services</h1>
      <div className="mycontent-left">
      <ul style={{listStyle:"none"}}>
        { names.map(value=>(<li className="mt-3 mb-3"  key={value._id}>
         <HashLink smooth to={`/services/${value.name}/${value._id}#toppage`}><NavLink activeClassName="clicked links"    className="links" to={`/services/${value.name}/${value._id}`} style={{ textDecoration:"none"}}>{value.name}</NavLink></HashLink>  </li>))}
          
      </ul>
      </div>
      
    </div>
    )
}

export default AllServices
