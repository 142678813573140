import React from 'react'

function Loading() {
    return (
        <div className="page-content ">						
  <div id="loading_page">		
			<div className="container_logo pb-5">
          <div className="rotate_logo">
            <div className="face_logo head">
             	<img src="../../imgs/loading-logo.svg" />					
            </div>
            <div className="face_logo tail">
              <img src="../../imgs/loading-page-inverse.svg" />
            </div>
          </div>        
        </div>        
			<div className="txt">
        <h4>Loading page...</h4>
			</div>
	 </div>
 
	</div>
 

    )
}

export default Loading
