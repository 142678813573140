import React from 'react'
import { Helmet } from 'react-helmet'
import Project from "../../Project"
function SavPage() {
    return (
      <>  <div className="container mt-lg-4 pt-lg-4 mt-md-0 pt-md-0 pt-0 mt-0 sav-page-container" id="toppage">
            <Helmet>
          <title>SAV | Vtopo BTP </title>
        </Helmet>
            <div className="row my-5   row-sav-st">
           <div className="col-md-6 col-lg-6 col-12 mt-lg-0 mt-md-0 mt-5 pt-2 pt-lg-0 pt-md-0">
               <h1>Service Technique </h1>
               <p className="text-left">
               VTOPO BTP offre une large gamme de services techniques ou services après-vente permet à nos techniciens qualifiés de procéder à l’entretien complet et à la réparation des instruments topographiques . 
<br/>
Différents niveaux de maintenance sont proposés à la carte, en fonction de chaque instrument et de leur utilisation.

               </p>
           </div>
           <div className="col-md-6 col-lg-6 col-12 text-center">
           <figure>
        <img src="../../imgs/sav1-01.jpg" className="img-sav" style={{width:"100%"}}/></figure>
           </div>
           </div>
           <div className="row featurette my-5 ">
           
           
           <div className="col-md-6     order-md-2">
               <h1>Support Technique </h1>
               <p className="text-left">
               Parce que la satisfaction de nos clients passe par la qualité de nos instruments et, surtout, de notre service, VTOPO BTP se fait un point d’honneur de mettre en tout temps à leur disposition une équipe expérimentée capable d’offrir le service après-vente qui fera la différence. 
               <br/>*/Support à l’achat<br/>
                    */Support technique</p>
                

           </div>
           <div className="col-md-6   order-md-1">
           
           <figure className="mt-2">
        <img src="../../imgs/sav2-01.jpg" className="img-sav" style={{width:"100%"}}/></figure>
           </div>
           </div>
           <div className="row my-5 ">
           <div className="col-md-6 col-lg-6 col-12  ">
               <h1>Formation </h1>
               <p className="text-left">
               L'équipe VTOPO BTP s’assurera de vous offrir tout le support nécessaire pour permettre à votre entreprise d’être tout à fait autonome dans l’utilisation qu’elle désire en faire. Qu’il s’agisse de vous guider lors d’une première utilisation ou de former une équipe complète en chantier, nous saurons vous proposer la solution qui répond le mieux à vos besoins.

              </p>
              <ul>
                  <li>Utilisation des appareils </li>
                  <li>Formations et démonstrations</li>
              </ul>
           </div>
           <div className="col-md-6 col-lg-6 col-12 text-center  ">
           <figure>
        <img src="../../imgs/formation.JPG" className="img-sav" style={{width:"100%"}}/></figure>
           </div>
            
            
        </div>
        </div>
        <Project/>
        </>
    )
}

export default SavPage
