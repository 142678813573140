import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import Loading from "../../Loading";
import {FormProject} from "./FormProject";

function EditProject({match}) {
    const [project,setProject]=useState(null)
    
    useEffect(() => {
    Axios.get(`/project/get-one/${match.params.id}`)
                .then((result)=>setProject(result.data))
                .catch((error)=>console.log(error.message));
            }, [match.params.id])

    return project ? <FormProject preloadedValues={project} match={match.params.id}/> : <Loading/>
}

export default EditProject
