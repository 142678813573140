import React,{useState,useEffect} from 'react';
import Axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import { UncontrolledCollapse, CardBody, Card} from 'reactstrap';
import { Helmet } from 'react-helmet'
import Project from "../../Project";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function ProductDetail({match}) {
    const [products,setProducts]=useState([]);
 
    useEffect(() => {
        Axios.get(`/category/products/${match.params.id}`)
             .then(res=>setProducts(res.data.products))
             .catch(err=>console.log(err.message))
    }, [match.params.id]);
    return (
      <>
        <div className="container pt-lg-5 pt-md-0 pt-0"  id="toppage">
          <Helmet>
          <title>{match.params.name} | Vtopo BTP </title>
        </Helmet>
             <h1 className="text-center mt-5 pt-5 mb-5 ">{match.params.name}</h1>
        
                {products.map((item,index)=>(<>
                
                {index % 2 ==0 ? <> 
                <div className="row featurette" key={item._id}>
      <div className="col-md-6 ml-2" >
      <h2 className="featurette-heading ">{item.nameProduct}</h2> 
        <p >{item.introProduct}</p>
        <ul style={{listStyle:"none"}}>
            {
                item.characteristics.map((value,index)=><li key={index}>{value}</li>)
            }
        </ul>
        <div>
   <div id="toggler" style={{ marginBottom: '1rem', color:"#03985E", cursor:"pointer" }}><a >
    Documentations 
    </a></div> 
    <UncontrolledCollapse toggler="#toggler">
      <Card>
        <CardBody>
        <div>Fiche Technique : <a href={`../../files/${item.ficheTechnique}`} download> 
        <FontAwesomeIcon icon={faFilePdf}    style={{width:'5%',height:"5%",color:"#FF0000"}} /></a></div>
              
        </CardBody>
      </Card>
    </UncontrolledCollapse>
  </div>
      </div>
      <div className="col-md-4">
        <Carousel infiniteLoop useKeyboardArrows    >
                   {item.images.map((item,index)=>(
                   <div key={index}>
                   <img alt="produit"  src={`../../imgs/${item}`} style={{width:"100%",height:"75%"}} /> 
                         
                    </div>)) }
                    
                     
                </Carousel>
              
      </div>
    </div>
     
                 </> : <>
                 <div className="row featurette" key={item._id}>
      <div className="col-md-6 order-md-2 ml-2" >
       <h2 className="featurette-heading"> {item.nameProduct} </h2> 
        <p >{item.introProduct}</p>
        <ul style={{listStyle:"none"}}>
            {
                item.characteristics.map((value,index)=><li key={index}>{value}</li>)
            }
        </ul>
        <div>
        <div id="toggler" style={{ marginBottom: '1rem', color:"#03985E", cursor:"pointer" }}><a >
    Documentations 
    </a>
    </div> 
    <UncontrolledCollapse toggler="#toggler">
      <Card>
        <CardBody>
        <div>Fiche Technique : <a href={`../../files/${item.ficheTechnique}`} download> <FontAwesomeIcon icon={faFilePdf}  style={{width:'5%',height:"5%",color:"#FF0000"}} /></a></div>
              
        </CardBody>
      </Card>
    </UncontrolledCollapse>
  </div>
      </div>
      <div className="col-md-4 order-md-1">
      <Carousel infiniteLoop useKeyboardArrows    >
                   {item.images.map((item,index)=>(
                   <div key={index}>
                        <img alt="produit" src={`../../imgs/${item}`} style={{width:"100%",height:"75%"}} />
                         
                    </div>)) }
                    
                     
                </Carousel>     
                 </div>
    </div>
     
                 </>
                
                }
                
                </>))}
            </div>
            <Project/>
         </>
    )
}

export default ProductDetail
