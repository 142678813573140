import React,{ useState, useEffect} from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import Message from "../../Message";
import Progress from "../../Progress";
import { useHistory } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import Axios from "axios";
 

const formSchema = {
    name: yup.string().required("ce champ est obligatoire")
  };

const fieldsSchema = yup.object().shape({
    characteristics: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required("ce champ est obligatoire")
    .min(1,"ce champ est obligatoire"),
    nameProduct : yup
    .string()
    .required("ce champ est obligatoire"),
    introProduct : yup
    .string()
    .required("ce champ est obligatoire"),
    imgProduct: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
    return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
      }),
      cat:yup
    .string()
    .required("ce champ est obligatoire"),
      ficheTechnique: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être fichier pdf", (value) => {
    return (value && value[0].type === "application/pdf");
      }),


});


function AddProduct() {
    const history = useHistory();
     const [message, setMessage] = useState(null);
     const [uploadPercentage, setUploadPercentage] = useState(0);
    const { register, control, handleSubmit, formState, errors } = useForm({
        defaultValues: {
            characteristics: [{ name: "Caractéristiques" }]
          },
          resolver: yupResolver(fieldsSchema),
          mode: "onChange"});
      const { fields, append, remove } = useFieldArray(
        {
          control,
          name: "characteristics"
        }
      );
      const [categories, setCategories]=useState([]);
useEffect(() => {
       Axios.get("/category/get-all")
            .then(res=>setCategories(res.data))
            .catch(err=>console.log(err.message))
    }, [])
    
      const onSubmit = async (data,e) =>{
        e.preventDefault();
        const newProduct = new FormData()
        newProduct.append('imgProduct', data.imgProduct[0]);
        newProduct.append('nameProduct',data.nameProduct);
        newProduct.append('introProduct',data.introProduct);
        newProduct.append("ficheTechnique",data.ficheTechnique[0])
        newProduct.append("products",data.cat);
        for (let i = 0; i < data.characteristics.length; i++) {
            newProduct.append("characteristics", data.characteristics[i].name)
           }
        try {
            await Axios.post('/product/add-product', newProduct, {
                headers: {
                'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
               setUploadPercentage(
               parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
               )
               );}
               }).then(res=> {
                    const { message } = res.data;
                     setMessage(message);
                     if(!message.msgError){
                          setTimeout(()=>{
                         history.push('/gest-products');
                         },2000)}
                         }).catch(err=>{
               console.log(err.message)
                });
        } catch (error) {
            console.log(error.message)
        }

      } ;
     
       
    return (
    <div className="container mt-5">
              
        <div className="col-md-6 col-lg-6">
            <h1>Ajouter Produit </h1><br/>
            {message ? <Message message={message}/> : null}
        <form  onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
        <div className="form-group ">
                   
                   <label htmlFor="name"> Catégorie</label>
                   <select className={`${errors.cat? `form-control is-invalid`: `form-control` }`} name="cat" ref={register}  >
                       {
                           categories.map(item=>(
                               <option key={item._id} value={item._id}>{item.catName} </option>
                           ))
                       }
                        
                   </select>
                   <div className="error ">{errors.cat?.message}</div>
               </div>
            <div className="form-group">
           
                <label htmlFor="name">Nom du Produit</label>
                 <input type="text"  className={`${errors.nameProduct? `form-control is-invalid`: `form-control` }`} id="name" name="nameProduct" aria-describedby="emailHelp" ref={register}  />
                 <div className="error">{errors.nameProduct?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Intro</label>
                 <textarea type="text" className={`${errors.introProduct? `form-control is-invalid`: `form-control` }`} id="name" name="introProduct" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.introProduct?.message}</div>
              </div>
             
              
              <div className='form-group mb-4'>
            <label htmlFor="img">Image</label> 
  <input
   type='file'
   className={`${errors.imgProduct? `form-control is-invalid`: `form-control` }`}
   id='customFile'
   ref={register} 
   name="imgProduct"
 />
  <div className="error ">{errors.imgProduct?.message}</div>
 </div>
 <div className='form-group mb-4'>
            <label htmlFor="img">Fiche Technique</label> 
  <input
   type='file'
   className={`${errors.ficheTechnique? `form-control is-invalid`: `form-control` }`}
   id='customFile'
   ref={register} 
   name="ficheTechnique"
 />
  <div className="error ">{errors.ficheTechnique?.message}</div>
 </div>
 <div className="mb-4 mt-4">
     <label>Caractéristiques du Produit</label> 
     <button
type="button"  className="btn btn-primary ml-3"
onClick={() => {
append({ name: "" });
 }}
>
Ajouter
</button>
         
<ul className="mb-4 mt-4" style={{listStyle:"none"}}>
{fields.map((item, index) => {
 return (
    <div className="row mt-3">
     <div className="col-md-6">
<li key={item.id}>
<input
name={`characteristics[${index}].name`}
 defaultValue={`${item.name}`}
 ref={register({})}
 className={`${errors.characteristics? `form-control is-invalid` : `form-control ` }`}
 required
/>
<div className="error">{errors.characteristics?.message}</div>
</li>

</div>
<div className="col-md-6">
<button type="button" className="btn btn-danger" onClick={() => remove(index)}>
Supprimer
</button>
</div>
</div>
);
 })}
</ul>
</div>





              
              
             

 
 
 <Progress percentage={uploadPercentage} />
 
           
           <br/>
           <button type="submit" className="btn btn-primary"  >Submit</button>
       </form>
       </div>
       </div>
    )
}

export default AddProduct
