import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Helmet } from 'react-helmet';

function GestImg() {
    const [modal, setModal] = useState(false);
    const [index, setIndex]= useState("");
    const [id, setId]= useState("");
    
    const toggle = (index,id) => {
        setModal(!modal);
        setIndex(index); 
        setId(id)
         ;}
        const closeModal=()=>{
          setModal(!modal);
        }
    const [images, setImages]=useState([]);
    useEffect(() => {
        Axios.get("/product/get-all")
            .then(res=>setImages(res.data))
            .catch(err=>console.log(err.message))
        }, [])
        const deleteImage=async (index,id)=>{
            await  Axios.delete(`/product/image/delete/${index}/${id}`);
            setModal(false);
            window.location.reload(false);
            
           }
    return (
        <div className="container mt-5">
          <Helmet>
          <title>Gestion des Images | Vtopo BTP </title>
        </Helmet>
        <div className="col-lg-12 col-md-12 d-flex justify-content-between" style={{backgroundColor:'#000000'}}>
         <h1 className="justify-content-start" style={{color:'#FFFFFF'}}>Ajouter Image Produit</h1>
          <Link to="/add-picture" style={{color:"inherit", marginTop:"1%"}}><button className="btn btn-primary justify-content-end">Ajouter </button></Link>
         </div>
         <div className="row">
         
           
          {images.map(item=>(
          <div className="col-lg-6 col-md-6 mb-3 mt-3 " key={item._id}>
          <h3>{item.nameProduct}</h3>
          <table className="table table-hover">
          <tr>
           
          <th scope="col">image</th>
          <th scope="col">Actions</th>
          </tr>
          <tbody>
            
            {item.images.map((val,index)=>(<tr key={index}>
               
              <td style={{width:"30%"}}><img src={"../../imgs/"+val} style={{width:"50px", height:"50px"}} alt="category img"/></td>
              <td><button className="btn btn-danger" onClick={(e)=>toggle(val,item._id)} >Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </button></td> 
              </tr>))}
            </tbody>
            </table> 
          </div>
          ))}
          
<Modal isOpen={modal} index={index} id={id}  toggle={(e)=>closeModal}>
        <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
        <ModalBody>
        
L'image Sera Supprimée Définitivement  
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={(e)=>deleteImage(index,id)}>Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </Button>
          <Button color="secondary" onClick={(e)=>closeModal()}>Annuler</Button>
        </ModalFooter>
      </Modal>


 
         </div>
   </div>
    )
}

export default GestImg
