import React,{ useState,useEffect} from 'react';
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Message from "../../Message";
import Progress from "../../Progress";
const schema = yup.object().shape({
    img: yup
      .mixed()
      .required("ce champ est obligatoire")
      .test("type", "ce champ doit être image de type jpeg or png", (value) => {
        return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
      }),
      produitId:yup
    .string()
    .required("ce champ est obligatoire")
    
  });

function AddImage() {
    const history = useHistory();
     const [message, setMessage] = useState(null);
     const [uploadPercentage, setUploadPercentage] = useState(0);
     const { register , handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
      });
      const [products, setProducts]= useState([]);
 useEffect(() => {
        Axios.get("/product/get-all")
             .then(res=>setProducts(res.data))
             .catch(err=>console.log(err.message))
    }, []);
      
       
    
      const onSubmit = async (data,e)=>{
        e.preventDefault();
        const newImages = new FormData()
         
        newImages.append('produitId',data.produitId);
        for (let i = 0; i < data.img.length; i++) {
                newImages.append("img", data.img[i])
            }
            try {
          await Axios.post('/product/add-image', newImages, {
           headers: {
           'Content-Type': 'multipart/form-data'
           },
           onUploadProgress: progressEvent => {
          setUploadPercentage(
          parseInt(
           Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
          );}
          }).then(res=> {
             
                const { message } = res.data;
                setMessage(message);
                if(!message.msgError){
                     setTimeout(()=>{
                    history.push('/gest-pictures');
                    },2000)}
                
                
            }).catch(err=>{
          console.log(err.message)
           });
           
          } catch (err) {
          if (err.response.status === 500) {
           setMessage('There was a problem with the server');
           } else {
          setMessage(err.response.data.msg);
          }
          }
     
    }
    return (
        <div className="container mt-5">
              
        <div className="col-md-8 col-lg-8">
            <h1>Ajouter Image </h1><br/>
            {message ? <Message message={message}/> : null}
        <form  onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
            <div className="form-group">
           
                <label htmlFor="name">Product</label>
                <select 
                className={`${errors.produitId? `form-control is-invalid`: `form-control` }`}
                name="produitId"
                ref={register}>
                   {products.map(item=>(<option key={item._id} value={item._id} >{item.nameProduct}</option>))}
                     
                </select>
                <div className="error ">{errors.produitId?.message}</div>
                 </div>
               
               
              
              <div className='form-group mb-4'>
            <label htmlFor="img">Image</label> 
  <input
   type='file'
   className={`${errors.img? `form-control is-invalid`: `form-control` }`}
   id='customFile'
   ref={register} 
   name="img"
   multiple
 />
  <div className="error ">{errors.img?.message}</div>
 </div>

 
 
 <Progress percentage={uploadPercentage} />
 
           
           <br/>
           <button type="submit" className="btn btn-primary"  >Submit</button>
       </form>
       </div>
       </div>
    )
}

export default AddImage
