import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhoneAlt,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Message from "../../Message";
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
  name: yup.string().required("ce champ est obligatoire"),
  days: yup.string().required("ce champ est obligatoire"),
  email: yup
    .string()
    .email("l'email doit être un email valide")
    .required("ce champ est obligatoire"),
  msg: yup.string().required("ce champ est obligatoire"),
  type: yup.string().required("ce champ est obligatoire"),
});

function ContactNous() {
  const history = useHistory();
  const [contact, setContact]=useState([]);
  const [message, setMessage] = useState("");
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    Axios.get("/contact/get-all")
         .then(res=>setContact(res.data))
         .catch(err=>console.log(err.message))
}, []);
  const onSubmit = async (data, e) => {
    e.preventDefault();
    const newEmail = new FormData();
    newEmail.append("name", data.name);
    newEmail.append("email", data.email);
    newEmail.append("days", data.days);
    newEmail.append("type", data.type);
    newEmail.append("msg", data.msg);

    try {
      await Axios.post("/user/new-email", newEmail)

        .then((res) => {
          const { message } = res.data;
          setMessage(message);
          if (!message.msgError) {
            setTimeout(() => document.getElementById("myform").reset(), 1100);
            setTimeout(() => history.push("/#toppage"), 1300);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.msg);
      }
    }
  };

  return (
    <div className="container  pt-5 mb-3" id="toppage">
      <Helmet>
        <title>Contact | Vtopo BTP </title>
      </Helmet>
      <div className="row mt-5">
        {contact.map((item,index)=>(<div className="col-md-6 col-lg-6 " key={index}>
          <h3>Besoin de plus d'information? Communiquez avec nous.</h3>
          <div>
            {item.emailInfo&&<a href={`mailto:${item.emailInfo}`} style={{ color: "inherit" }}>
              <span aria-hidden="true">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="1x"
                  style={{ color: "#DF880F" }}
                />
              </span>{" "}
              {item.emailInfo}
            </a>}
            <br />
            {item.telInfo&&<a href={`tel:${item.telInfo}`} style={{ color: "inherit" }}>
              <span aria-hidden="true">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  size="1x"
                  style={{ color: "#DF880F" }}
                />
              </span>{" "}
              {item.telInfo}
            </a>}
          </div>
          <div>
            <ul style={{ listStyleType: "none" }}>
              {item.emailSc&&<li>
                <h3 className="mb-3 mt-2">Service Commercial </h3>
                <a
                  href={`mailto:${item.emailSc}`}
                  style={{ color: "inherit" }}
                >
                  <span aria-hidden="true">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="1x"
                      style={{ color: "#DF880F" }}
                    />
                  </span>{" "}
                  {item.emailSc}
                </a>
                <br />
            <a href={`tel:${item.telSc}`} style={{ color: "inherit" }}>
              <span aria-hidden="true">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  size="1x"
                  style={{ color: "#DF880F" }}
                />
              </span>{" "}
              {item.telSc}
            </a>
              </li>}
            </ul>
            <ul style={{ listStyleType: "none" }}>
              {item.emailSav&&<li>
                <h3 className="mb-3 mt-2">Service Après Vente </h3>
                <div>
                  <a
                    href={`mailto:${item.emailSav}`}
                    style={{ color: "inherit" }}
                  >
                    <span aria-hidden="true">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        size="1x"
                        style={{ color: "#DF880F" }}
                      />
                    </span>{" "}
                   {item.emailSav}
                  </a>
                  <br />
            <a href={`tel:${item.telSav}`} style={{ color: "inherit" }}>
              <span aria-hidden="true">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  size="1x"
                  style={{ color: "#DF880F" }}
                />
              </span>{" "}
              {item.telSav}
            </a>
                </div>
              </li>}
            </ul>
          </div>
        </div>))}
        <div className="col-md-6 col-lg-6 ">
          <div>
             
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9516.9962274432!2d-4.039385293746904!3d5.322313625222241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1eb2829bf3f6f%3A0x61c47ac40a94dcf!2sVTOPO%20BTP!5e0!3m2!1sen!2stn!4v1623849094453!5m2!1sen!2stn" 
            id="map"
            style={{border : "0"}} 
            allowFullScreen="" 
            loading="lazy"></iframe>
          </div>
          {message ? <Message message={message} /> : null}
          <form onSubmit={handleSubmit(onSubmit)} id="myform" className="mt-5">
            <div className="form-group">
              <label htmlFor="nom">Votre nom complet</label>
              <input
                type="text"
                className={`${
                  errors.name ? `form-control is-invalid` : `form-control`
                }`}
                id="nom"
                aria-describedby="nom"
                name="name"
                placeholder="Votre Nom..."
                ref={register}
              />
              <div className="error ">{errors.name?.message}</div>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Votre Courriel</label>
              <input
                type="email"
                className={`${
                  errors.email ? `form-control is-invalid` : `form-control`
                }`}
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Votre Courriel..."
                name="email"
                ref={register}
              />
              <div className="error ">{errors.email?.message}</div>
            </div>
            <div className="form-group">
              <label>
                Périodes auxquelles je désire être contacté par un
                arpenteur-géomètre
              </label>
              <input
                type="text"
                placeholder="Périodes auxquelles je désire être contacté par un arpenteur-géomètre"
                className={`${
                  errors.days ? `form-control is-invalid` : `form-control`
                }`}
                id="exampleInputdays1"
                aria-describedby="daysHelp"
                name="days"
                ref={register}
              />
              <div className="error ">{errors.days?.message}</div>
            </div>
            <div className="form-group">
              <label>Type de travail demandé </label>
              <br />

              <div className=" ">
                <label>
                  <input
                    id="type"
                    type="radio"
                    className={`${errors.type ? `is-invalid` : ``}`}
                    name="type"
                    ref={register}
                    value="Certificat de localisation"
                  />
                  Certificat de localisation
                </label>
              </div>
              <div className="  ">
                <label>
                  <input
                    type="radio"
                    className={`${errors.type ? `is-invalid` : ``}`}
                    name="type"
                    ref={register}
                    value="Certificat de localisation d'une partie privative (condo)"
                  />
                  Certificat de localisation d'une partie privative (condo)
                </label>
              </div>
              <div className="  ">
                <label>
                  <input
                    type="radio"
                    className={`${errors.type ? `is-invalid` : ``}`}
                    name="type"
                    ref={register}
                    value="Plan projet d'implantation + Implantation + Certificat de
                    localisation"
                  />
                  Plan projet d'implantation + Implantation + Certificat de
                  localisation
                </label>
              </div>
              <div className="  ">
                <label>
                  <input
                    type="radio"
                    className={`${errors.type ? `is-invalid` : ``}`}
                    name="type"
                    ref={register}
                    value="Délimitation de propriété (Bornage ou Piquetage)"
                  />
                  Délimitation de propriété (Bornage ou Piquetage)
                </label>
              </div>
              <div className="">
                <label>
                  <input
                    type="radio"
                    className={`${errors.type ? `is-invalid` : ` `}`}
                    name="type"
                    ref={register}
                    value="Division et/ou fusion de terrain (Opérations cadastrales)"
                  />
                  Division et/ou fusion de terrain (Opérations cadastrales)
                </label>
              </div>
              <div className="  ">
                <label>
                  <input
                    type="radio"
                    className={`${errors.type ? `is-invalid` : ` `}`}
                    name="type"
                    ref={register}
                    value="Expertise en milieu naturel"
                  />
                  Expertise en milieu naturel
                </label>
              </div>
              <div className="error ">{errors.type?.message}</div>
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                placeholder="votre Message"
                className={`${
                  errors.msg ? `form-control is-invalid` : `form-control`
                }`}
                id="message"
                rows="6"
                name="msg"
                ref={register}
              />
              <div className="error ">{errors.msg?.message}</div>
            </div>

            <button type="submit" className="btn btn-btn-green">
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactNous;
