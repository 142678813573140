import React,{useState,useEffect} from 'react';
import Axios from "axios";
import { HashLink as Link } from "react-router-hash-link";

function ServiceSection() {
  const [services,setServices]=useState([]);
  useEffect(() => {
    Axios.get("/service/frontend/get-visible-services")
         .then(res=>setServices(res.data))
         .catch(err=>console.log(err.message))
  }, [])
    return (
        <div className="container "> 
        <h1 className="text-center my-3">Services</h1>
            <div className="row ">
              {services.map(item=>(
              <div className="col-md-4 col-12 my-2" key={item._id}>
           <div className="snip1369 orange">
  <img src={`../../imgs/${item.img}`} alt="pr-sample15" />
  <div className="image">
      <img src={`../../imgs/${item.img}`} alt="pr-sample15" /></div>
  <figcaption >
    <h4>{item.name} </h4>
    <p>{item.intro}</p>
  </figcaption>
  <Link smooth to={`/services/${item.name}/${item._id}#toppage`} style={{color:"inherit"}}><span className="read-more">
     
    Voir Plus  </span></Link>
 
</div>
</div>))}
 
 
 
</div>
        </div>
    )
}

export default ServiceSection
