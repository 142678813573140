import React,{useState,useEffect} from 'react';
import Axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendarAlt,faMapMarkerAlt,faBuilding} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { Helmet } from 'react-helmet'
import Project from "../../Project";


function NosProjet() {
    const [projects, setProjects]=useState([]);
    useEffect(() => {
        Axios.get("/project/get-all")
         .then(res=>setProjects(res.data))
         .catch(err=>console.log(err.message))
    }, [])

    return (
       <> <div className="container pt-5" id="vente-page"  >
            <Helmet>
          <title>Nos Projets | Vtopo BTP </title>
        </Helmet>
                { projects.map((item,index)=>(<>
                
                {index % 2 ===0 ?  
                  <div className="row ">       
      <div className="col-md-6" key={item._id}>
     <h1>{item.nameProject}</h1>
        <p className="lead mr-3">{item.descriptionProject}</p>
        <FontAwesomeIcon icon={faCalendarAlt}  style={{width:'10%',height:"10%",color:"#000"}} />{moment(item.date).format('DD-MM-YYYY')} <br/>
        <FontAwesomeIcon icon={faMapMarkerAlt}  style={{width:'10%',height:"10%",color:"#000"}} />{item.place} <br/>
        <FontAwesomeIcon icon={faBuilding}  style={{width:'10%',height:"10%",color:"#000"}} />{item.client} 
       </div>
      <div className="col-md-6">
      <img className="bd-placeholder-img" src={`../../imgs/${item.imgProject}`} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" alt="img" />
      </div>
     </div>
   
                  : <div className="row mt-5 mb-3" >
             
      <div className="col-md-6  order-md-2 " key={item._id}>
      <h1> {item.nameProject} </h1> 
        <p className="lead">{item.descriptionProject}</p>
        <FontAwesomeIcon icon={faCalendarAlt}  style={{width:'10%',height:"10%",color:"#000"}} />{moment(item.date).format('DD-MM-YYYY')} <br/>
        <FontAwesomeIcon icon={faMapMarkerAlt}  style={{width:'10%',height:"10%",color:"#000"}} />{item.place} <br/>
        <FontAwesomeIcon icon={faBuilding}  style={{width:'10%',height:"10%",color:"#000"}} />{item.client} 
      </div>
      <div className="col-md-6 order-md-1">
     <img className="bd-placeholder-img" src={`../../imgs/${item.imgProject}`} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" alt="img"/>  
      </div>
    
    
                 </div>
                
                }
                
                </>))}
           
        </div>
        <Project/>
        </>
    )
}

export default NosProjet
