import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt,faEdit} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Helmet } from 'react-helmet';

function GestContact() {
    const [contact, setContact]= useState([]);
     
    
    useEffect(() => {
        Axios.get("/contact/get-all")
             .then(res=>setContact(res.data))
             .catch(err=>console.log(err.message))
    }, []);
    
    return (
        <div className="container mt-5">
        <Helmet>
        <title>Gestion de Contact | Vtopo BTP </title>
      </Helmet>
      <div className="col-lg-12 col-md-12 d-flex justify-content-between" style={{backgroundColor:'#000000'}}>
       <h1 className="justify-content-start" style={{color:'#FFFFFF'}}>Gestion Contact</h1>
        
       </div>
       <div className="col-lg-12 col-md-12 ">
       <table className="table table-hover">
<thead>
<tr>
<th scope="col">Email</th>
<th scope="col">Tel</th>
<th scope="col">Actions</th>

</tr>
</thead>
<tbody>
{contact.map(item=>(
<tr key={item._id} >
<th scope="row">
{item.emailInfo}
</th>
<th scope="col">{item.telInfo}</th>
<td>
<Link to={`/edit-contact/${item._id}`}  style={{color:"inherit"}}>  <button className="btn btn-info" >Modifier <FontAwesomeIcon icon={faEdit} style={{color:"#fff"}}/> </button></Link> 
</td> 
</tr>
))}
 

</tbody>
</table>
       </div>
 </div>
    )
}

export default GestContact
