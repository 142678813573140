import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import Loading from "../../Loading";
import {FormProduct}  from './FormProduct';
function EditProduct({match}) {
    const [product,setProduct]=useState(null)
    
    useEffect(() => {
    Axios.get(`/product/get-one/${match.params.id}`)
                .then((result)=>setProduct(result.data))
                .catch((error)=>console.log(error.message));
            }, [match.params.id])
    return product ? <FormProduct preloadedValues={product} match={match.params.id}/> : <Loading/>
}

export default EditProduct
