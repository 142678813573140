import React, {  useState, useEffect, useRef } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
 
import { HashLink } from "react-router-hash-link";
import {ReactComponent as Caret } from "../Components/icons/caret.svg";
import Axios from "axios";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"; 

const Navigation = () => {
    
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

   
  useEffect(() => {
    $("body").on("mouseenter mouseleave", ".nav-item", function (e) {
      if ($(window).width() > 750) {
        var _d = $(e.target).closest(".nav-item");
        _d.addClass("show");
        setTimeout(function () {
          _d[_d.is(":hover") ? "addClass" : "removeClass"]("show");
        }, 1);
      }
    });
  }, []);
  const navRef = useRef(null); 
  function logit() {
    if (window.pageYOffset > 10) { 
     navRef.current.classList.add("test-nav-scroll")
    }
    else { 
      navRef.current.classList.remove("test-nav-scroll")
    }
   
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    } 
    watchScroll(); 
    return () => {
      window.removeEventListener("scroll", logit); 
    };
  },[]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    Axios.get("/category/get-all")
      .then((res) => setCategories(res.data))
      .catch((err) => console.log(err.message));
  }, []);
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  },[]);
  const handleClickOutside = event => {
    const { current: wrap } = navRef;
    if (wrap && !wrap.contains(event.target)) {
      setIsOpen(false)
     
    }
  };
  return (
    <div>
      
        <div className="navigation-wrap bg-white  sticky-top" ref={navRef}  >
          <div className="row">
            <div className="col-10">
              <Navbar color="white" light expand="lg">
                <HashLink
                  onClick={toggle}
                  smooth
                  to={{ pathname: "/", hash: "#toppage" }}
                  style={{ color: "inherit", marginLeft: "10%" }}
                  
                >
                  <NavbarBrand rel="noreferrer" >
                    <img src="../../imgs/loading-logo.svg" alt="logo"   />
                  </NavbarBrand>
                </HashLink>

                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav navbar className="navbar-nav ml-auto py-4 py-md-0">
                    <NavItem className="nav-item pl-4 pl-md-0 ml-0 ml-md-4  ">
                      <HashLink
                        onClick={toggle}
                        smooth
                        to={{ pathname: "/", hash: "#toppage" }}
                      >
                        <NavLink
                          exact
                          to={{ pathname: "/", hash: "#toppage" }}
                          activeClassName="on"
                          className="nav-link"
                        >
                          Accueil
                        </NavLink>
                      </HashLink>
                    </NavItem>
                    <NavItem className="nav-item pl-4 pl-md-0 ml-0 ml-md-4  ">
                      <HashLink
                        onClick={toggle}
                        to="/entreprise#toppage"
                        smooth
                      >
                        <NavLink
                          to="/entreprise"
                          activeClassName="on "
                          className="nav-link"
                          onClick={toggle}
                        >
                          Entreprise
                        </NavLink>
                      </HashLink>
                    </NavItem>

                    <NavItem className="nav-item pl-4 pl-md-0 ml-0 ml-md-4  ">
                      <HashLink to="/services#toppage" smooth>
                        <NavLink
                          to="/services"
                          activeClassName="on"
                          className="nav-link"
                          onClick={toggle}
                        >
                          Services
                        </NavLink>
                      </HashLink>
                    </NavItem>

                    <NavItem className="nav-item pl-4 pl-md-0 ml-0 ml-md-4  ">
                      <HashLink
                        onClick={toggle}
                        smooth
                        to="/nos-projets#vente-page"
                      >
                        <NavLink
                          to="/nos-projets"
                          activeClassName="on  "
                          className="nav-link"
                          onClick={toggle}
                        >
                          Nos Projets
                        </NavLink>
                      </HashLink>
                    </NavItem>
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      className="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                    >
                      <DropdownToggle nav>
                        <HashLink smooth to="/vente#vente-page">
                          <NavLink
                            to="/vente"
                            activeClassName="on "
                            className="nav-link"
                          >
                            Vente  <Caret style={{width : "7px"}}/>
                          </NavLink>
                         
                        </HashLink>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu">
                        {categories.map((item) => (
                           <HashLink
                              to={`/vente/${item.catName}/${item._id}#toppage`}
                              className="dropdown-item"
                              style={{
                                color: "inherit",
                                textDecoration: "none",
                              }}
                              key={item._id}
                              onClick={toggle}
                            >
                              {item.catName}
                            </HashLink> 
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem className="nav-item pl-4 pl-md-0 ml-0 ml-md-4  ">
                      <HashLink onClick={toggle} smooth to="/sav#toppage">
                        <NavLink
                          to="/sav"
                          activeClassName="on nav-link"
                          className="nav-link"
                        >
                          SAV
                        </NavLink>
                      </HashLink>
                    </NavItem>
                    <NavItem className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                      <HashLink onClick={toggle} smooth to="/contact#toppage">
                        <NavLink
                          to="/contact"
                          activeClassName="on nav-link"
                          className="nav-link"
                        >
                          Contact
                        </NavLink>
                      </HashLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default Navigation;
