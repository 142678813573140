import React, { useState, useContext } from "react";
import AuthService from "../Services/AuthService";
import Message from "../Components/Message";
import { AuthContext } from "../Context/AuthContext";
import { Helmet } from "react-helmet";
const Login = (props) => {
  const [user, setUser] = useState({ username: "", password: "" });
  const [message, setMessage] = useState(null);
  const authContext = useContext(AuthContext);

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    AuthService.login(user).then((data) => {
      const { isAuthenticated, user, message } = data;
      if (isAuthenticated) {
        authContext.setUser(user);
        authContext.setIsAuthenticated(isAuthenticated);
        props.history.push("/gest-category");
      } else setMessage(message);
    });
  };

  return (
    <div className="container mt-5 mb-3 ">
      <Helmet>
        <title>Login | Vtopo BTP </title>
      </Helmet>
      <div className="row">
        <div className="col-lg-3 col-md-2"></div>
        <div className="col-lg-6 col-md-8 login-box">
          <div className="col-lg-12">
            <img src="../../imgs/loading-logo.svg" />
          </div>
          <div className="col-lg-12 login-title">Log In</div>

          <div className="col-lg-12 login-form">
            <div className="col-lg-12 login-form">
              {message ? <Message message={message} /> : null}
              <form onSubmit={onSubmit}>
                <div className="form-group-login">
                  <label className="form-control-label-login">USERNAME</label>
                  <input
                    type="text"
                    className="form-control"
                    id="login-input"
                    name="username"
                    onChange={onChange}
                  />
                </div>
                <div className="form-group-login">
                  <label className="form-control-label-login">PASSWORD</label>
                  <input
                    type="password"
                    className="form-control"
                    id="login-password"
                    onChange={onChange}
                    name="password"
                  />
                  <small>
                    <a href="/vtopo/UF3saGVSwTtVNmKb/mot-de-passe-oublié">
                      Mot de passe oublié ?
                    </a>
                  </small>
                </div>

                <div className="col-lg-12 loginbttm-login mb-4">
                  <div className="col-lg-6 login-btm login-button">
                    <button type="submit" className="btn btn-outline-primary">
                      LOGIN
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
