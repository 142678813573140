import React,{useState, useEffect, Fragment} from 'react';
import Axios from "axios";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet'
import Project from "../../Project";

function Vents() {
    const [categories, setCategories]=useState([])
    useEffect(() => {
      Axios.get("/category/get-all")
           .then(res=>setCategories(res.data))
           .catch(err=>console.log(err.message))
    }, [])

    return (
     <>
        <div className="container pt-5" id="vente-page"  >
          <Helmet>
          <title>Vente | Vtopo BTP </title>
        </Helmet>
            
                { categories.map((item,index)=>(<Fragment key={index}>
                
                {index % 2 ===0 ? 
                   
                <div className="row featurette my-4" key={item._id}>
      <div className="col-md-6 ml-2 " >
     <Link to={`/vente/${item.catName}/${item._id}`} style={{color:"inherit"}}><h2 className="featurette-heading ">{item.catName}</h2></Link>
        <p className="lead">{item.intro}</p>
      </div>
      <div className="col-md-4 ">
      <Link to={`/vente/${item.catName}/${item._id}`} style={{color:"inherit"}}><img className="bd-placeholder-img mb-5" src={`../../imgs/${item.img}`} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" alt="img" /></Link>
      </div>
    </div>
     
                  :  
                 
                 <div className="row featurette my-5" key={item.img}>
                   
      <div className="col-md-6 ml-2 order-md-2 my-2" >
      <Link to={`/vente/${item.catName}/${item._id}`} style={{color:"inherit"}}> <h2 className="featurette-heading"> {item.catName} </h2> </Link>
        <p className="lead">{item.intro}</p>
      </div>
      <div className="col-md-4 order-md-1 my-2">
      <Link to={`/vente/${item.catName}/${item._id}`} style={{color:"inherit"}}><img className="bd-placeholder-img mb-5" src={`../../imgs/${item.img}`} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" alt="img"/> </Link> 
      </div>
    </div>
     
               
                
                }
                
                </Fragment>))}
            </div>
            <Project/>
        </>
    )
}

export default Vents
