import React, {useState,useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
 
import AuthService from "../../Services/AuthService";
import { AuthContext } from "../../Context/AuthContext";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav, 
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
  } from "reactstrap";
function NavigationAuth() {
    const {  user, setIsAuthenticated, setUser } = useContext(
        AuthContext
      );
    const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const onClickLogoutHandler = () => {
    AuthService.logout().then((data) => {
      if (data.success) {
        history.push("/");
        setUser(data.user);
        setIsAuthenticated(false);
      }
    });
  };
    return (
        <Navbar color="light" light expand="lg" className="sticky-top"   >
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <li className="  nav-link mr-2 ml-3">
                <NavLink
                  style={{ color: "inherit", textDecoration: "none" }}
                  activeClassName="on"
                  to="/gest-category" 
                >
                  Gestion Catégorie
                </NavLink>
              </li>
              <li className=" nav-link mr-2">
                <NavLink
                  to="/gest-products"
                  style={{ color: "inherit", textDecoration: "none" }}
                  activeClassName="on"
                >
                  Gestion Produit
                </NavLink>
              </li>
              <li className=" nav-link mr-2">
                <NavLink
                  to="/gest-pictures"
                  style={{ color: "inherit", textDecoration: "none" }}
                  activeClassName="on"
                >
                  Gestion Iamges Produit
                </NavLink>
              </li>

              <li className=" nav-link mr-2">
                <NavLink
                  to="/gest-service"
                  style={{ color: "inherit", textDecoration: "none" }}
                  activeClassName="on"
                >
                  Gestion Services
                </NavLink>
              </li>
              <li className=" nav-link mr-2">
                <NavLink
                  to="/gest-carousel"
                  style={{ color: "inherit", textDecoration: "none" }}
                  activeClassName="on"
                >
                  Gestion Carousel
                </NavLink>
              </li>
              <li className=" nav-link mr-2">
                <NavLink
                  to="/gest-project"
                  style={{ color: "inherit", textDecoration: "none" }}
                  activeClassName="on"
                >
                  Gestion projets
                </NavLink>
              </li>
              <li className="nav-link mr-2">
                <NavLink
                to="/gest-contact"
                style={{ color: "inherit", textDecoration: "none" }}
                  activeClassName="on"
                >
                  Gestion Contact
                </NavLink>
              </li>
              {user.role === "admin" ? (
                <li className=" nav-link">
                  <NavLink
                    to="/gest-users"
                    style={{ color: "inherit", textDecoration: "none" }}
                    activeClassName="on"
                  >
                    Gestion Utilisateurs
                  </NavLink>
                </li>
              ) : null}
            </Nav>
            <NavbarText>
              <UncontrolledDropdown>
                <DropdownToggle nav>
                  <img
                    src={user.img}
                    alt=""
                    style={{ width: "50%" }}
                    className="rounded-circle"
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="btn btn-link   ">
                    <NavLink to={`/profile/${user.username}`}>Profile</NavLink>
                  </DropdownItem>
                  <DropdownItem
                    className="btn btn-link  "
                    onClick={onClickLogoutHandler}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavbarText>
          </Collapse>
        </Navbar>
    )
}

export default NavigationAuth
