import React,{useContext,useState} from 'react';
import {Helmet} from "react-helmet"
import { AuthContext } from "../../../Context/AuthContext";
import Axios from "axios";
import Message from "../../Message";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
const schema = yup.object().shape({
    username:yup
    .string()
    .required("ce champ est obligatoire"),
    oldpassword:yup
    .string()
    .required("ce champ est obligatoire"),
    password:yup
    .string()
    .required("ce champ est obligatoire")
    .min(8,"Le mot de passe doit contenir plus de 8 caractères!"),
    passwordConfirmation: yup.string()
     .oneOf([yup.ref('password'), null], 'les mots de passe doivent correspondre'),
     
    
  });


function Profile() {
    const {user} = useContext(AuthContext);
    const [message, setMessage] = useState(null);
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues :{
            username : user.username
        },
        resolver: yupResolver(schema)
      });
      const onSubmit =async(data,e)=>{
        e.preventDefault();
        const updatedProfile = new FormData()
        updatedProfile.append("username",data.username)
        updatedProfile.append("oldPassword",data.oldpassword)
        updatedProfile.append("password",data.password)
         await Axios.put(`/user/edit-profile/${user.username}`,updatedProfile)
        .then((res)=>{
            const { message } = res.data;
            setMessage(message);
            if(!message.msgError){
                setMessage(message);
                 setTimeout(()=>{
                history.push('/gest-category');
                },2000)}
            })
        .catch(err=> setMessage({msgBody:"Error has occured Check Name And Password ",msgError:true}))
        };

    return (
        <div className="container mt-5">
            <Helmet>
          <title>Profile | Vtopo BTP </title>
        </Helmet>
            <div className="row">
                <div className="col-md-10 col-lg-10 col-10">
                <h3>Modifier Profile</h3>
            {message ? <Message message={message}/> : null}
            {console.log("2",message)}
            <form onSubmit={handleSubmit(onSubmit)}  >
               <div className="form-group">
                   <label htmlFor="name"  >Nom ou E-mail</label>
                <input type="text" 
                       name="username" 
                        
                       ref={register}
                       className={`${errors.username? `form-control is-invalid`: `form-control` }`}
                       placeholder="Enter Username"/>
                       <div className="error ">{errors.username?.message}</div>
                       </div>
                       <div className="form-group">
                <label htmlFor="password" >Mot De Passe Actuel </label>
                <input type="password" 
                       name="oldpassword"
                         
                       ref={register}
                       className={`${errors.oldpassword? `form-control is-invalid`: `form-control` }`}
                       placeholder="Enter Password"/>
                       <div className="error ">{errors.oldpassword?.message}</div>
                </div>
                       <div className="form-group">
                <label htmlFor="password" >Nouveau Mot De Passe </label>
                <input type="password" 
                       name="password"
                         
                       ref={register}
                       className={`${errors.password? `form-control is-invalid`: `form-control` }`}
                       placeholder="Enter Password"/>
                       <div className="error ">{errors.password?.message}</div>
                </div>
                <div className="form-group">
           
                <label htmlFor="name">Confirmation de Nouveau Mot De Passe</label>
                 <input 
                 type="password"
                 name="passwordConfirmation"
                 
                  ref={register} 
                 placeholder="Enter Password" 
                 className={`${errors.passwordConfirmation? `form-control is-invalid`: `form-control` }`}     />
                 <div className="error ">{errors.passwordConfirmation?.message}</div>
              </div>
              <button className="btn btn-primary">Modifier</button>
              </form>
                </div>
            </div>
        </div>
    )
}

export default Profile
