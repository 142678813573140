import React,{useState} from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import Message from "../../Message";

const formSchema = {
  name: yup.string() 
};

const fieldsSchema = yup.object().shape({
  characteristics: yup
  .array()
  .of(yup.object().shape(formSchema)),
  name : yup
  .string()
  .required("ce champ est obligatoire"),
  intro : yup
  .string()
  .required("ce champ est obligatoire"),
  description : yup
  .string(),
  


});
export  function FormService({preloadedValues, match}) {
    const [message, setMessage] = useState(null);
    const history = useHistory();
    const { register, control, handleSubmit, errors, getValues } = useForm({
        defaultValues: preloadedValues,
          resolver: yupResolver(fieldsSchema),
          mode: "onChange"
        });
      const { fields, append, remove } = useFieldArray(
        {
          control,
          name: "characteristics"
        }
      );
      
      const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedService = new FormData()
        updatedService.append('img', data.photo[0]);
        updatedService.append('banner', data.pic[0]);
        updatedService.append("name",data.name);
        updatedService.append("intro",data.intro);
        updatedService.append("description",data.description);
        updatedService.append('hidden',data.hidden);
         for (let i = 0; i < data.characteristics.length; i++) {
           updatedService.append("characteristics", data.characteristics[i].name)
           }
            console.log(data)
          await Axios.put(`/service/edit-service/${match}`,updatedService)
        .then((res)=>{
            const { message } = res.data;
            setMessage(message);
            if(!message.msgError){
                 setTimeout(()=>{
                history.push('/gest-service');
                },2000)}
            })
        .catch(err=>console.log(err.message))
        };
    return (
        <div className="container mt-5">
             <div className="row row-a">
                 <div className="col-md-10 col-lg-10">
                     <h1>Modifier Service </h1><br/>
                     {message ? <Message message={message}/> : null}
                  <form   onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data"  >
                      
                     <div className="form-group">
                         <label htmlFor="name">Nom de Service</label>
                          <input type="text" className={`${errors.name? `form-control is-invalid`: `form-control` }`} id="name" name="name" ref={register}/>
                          <div className="error ">{errors.name?.message}</div>
                       </div>
                       <div className="form-group">
                         <label htmlFor="name">intro</label>
                          <textarea className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro"  ref={register}  />
                          <div className="error ">{errors.intro?.message}</div>
                       </div> 
                       <div className="form-group">
                         <label htmlFor="name">Description</label>
                          <textarea className={`${errors.description? `form-control is-invalid`: `form-control` }`} id="name" name="description"  ref={register}  />
                          <div className="error ">{errors.description?.message}</div>
                       </div>
                       <div className="form-group">
                <label>Page Accueil </label><br/>
                
                <select className={`${errors.hidden? `form-control is-invalid`: `form-control` }`} name="hidden" ref={register}  >
                    <option value="false">visible</option>
                    <option value="true">caché</option>
                  </select>
 
            </div>  
             
                       <div className="mb-4 mt-4">
     <label>modifie une Caractéristique </label> 
     <button
type="button"  className="btn btn-primary ml-3"
onClick={() => {
append({ name: "" });
 }}
>
Ajouter
</button>
         
<ul className="mb-4 mt-4" style={{listStyle:"none"}}>
{fields.map((item, index) => {
 return (
    <div className="row mt-3">
     <div className="col-md-6">
<li key={item.id}>
   
<input
name={`characteristics[${index}].name`}
 defaultValue={`${preloadedValues.characteristics[index]}`}
 ref={register({})}
 className="form-control"
 
/>
</li>

</div>
<div className="col-md-6">
<button type="button" className="btn btn-danger" onClick={() => remove(index)}>
Supprimer
</button>
</div>
</div>
);
 })}
</ul>
</div>
                        <div className='custom-file mb-4 col-md-6 col-lg-6'>
                          <label>Image</label>
                        <input
                             type='file'
                             className={`${errors.photo? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="photo"
                             ref={register}
                              />
                              <div className="error ">{errors.photo?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.img}`} style={{width:'40%',height:"40%"}} alt="" />
           </div>
           </div>
           <div className='custom-file mt-3 mb-4 col-md-6 col-lg-6'>
             <label>Image De Couverture</label>
                        <input
                             type='file'
                             className={`${errors.pic? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="pic"
                             ref={register}
                              />
                              <div className="error ">{errors.pic?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.banner}`} style={{width:'40%',height:"40%"}} alt="" />
           </div>
           </div>
         <button type="submit" className="btn btn-primary"  >Submit</button>
                </form>
                </div>
                </div>
                </div>
    )
}
