import React,{ useState,useEffect} from 'react';
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Message from "../../Message";
import Progress from "../../Progress"
const schema = yup.object().shape({
    img: yup
      .mixed()
      .required("ce champ est obligatoire")
      .test("type", "ce champ doit être image de type jpeg or png", (value) => {
        return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
      }),
    lien:yup
    .string()
    .required("ce champ est obligatoire"),
    intro:yup
    .string(75, "ce champ ne doit pas passer 75 caractères"),
    titre:yup
    .string(25, "ce champ ne doit pas passer 25 caractères")
    
  });

function AddCarousel() {
    const history = useHistory();
     const [message, setMessage] = useState(null);
     const [products, setProducts] =useState([])
     const [uploadPercentage, setUploadPercentage] = useState(0);
     const { register , handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
      });
      useEffect(() => {
        Axios.get("/category/get-all")
             .then(res=>setProducts(res.data))
             .catch(err=>console.log(err.message))
      }, [])
    
      const onSubmit = async (data,e)=>{
        e.preventDefault();
        const newCarousel = new FormData()
        newCarousel.append('img', data.img[0])
        newCarousel.append('lien',data.lien)
        newCarousel.append('intro',data.intro)
        newCarousel.append('titre',data.titre)
         
        try {
          await Axios.post('/carousel/add-carousel', newCarousel, {
           headers: {
           'Content-Type': 'multipart/form-data'
           },
           onUploadProgress: progressEvent => {
          setUploadPercentage(
          parseInt(
           Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
          );}
          }).then(res=> {
             
                const { message } = res.data;
                setMessage(message);
                if(!message.msgError){
                     setTimeout(()=>{
                    history.push('/gest-carousel');
                    },2000)}
                
                
            }).catch(err=>{
          console.log(err.message)
           });
           
          } catch (err) {
          if (err.response.status === 500) {
           setMessage('There was a problem with the server');
           } else {
          setMessage(err.response.data.msg);
          }
          }
     
    }
    return (
        <div className="container mt-5">
              
        <div className="col-md-8 col-lg-8">
            <h1>Ajouter Image Carousel </h1><br/>
            {message ? <Message message={message}/> : null}
        <form  onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
            <div className="form-group">
           
                <label htmlFor="name">Titre</label>
                 <input type="text"  className={`${errors.titre? `form-control is-invalid`: `form-control` }`} id="name" name="titre" aria-describedby="emailHelp" ref={register}  />
                 <div className="error ">{errors.titre?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Intro</label>
                 <textarea  className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro" aria-describedby="emailHelp" ref={register}   />
                 <div className="error ">{errors.intro?.message}</div>
              </div>
              <div className="form-group">
                <label htmlFor="lien">Lien</label>
              <select className="custom-select"  name="lien" ref={register}>
                 
                      <option value="/nos-projets">Page Nos Projets</option>
                      <option value="/vente">Page Vente</option>
                      <option value="/services">Page Services</option>
                      <option value="/sav">Page SAV</option>
                      <option value="/contact">Page Contact</option>
                      {
                        products.map(item=><option key={item._id} value={`/vente/${item.catName}/${item._id}`} >Page {item.catName}</option>)
                      }
              </select>
              </div>
              
              <div className='form-group mb-4'>
            <label htmlFor="img">Image</label> 
  <input
   type='file'
   className={`${errors.img? `form-control is-invalid`: `form-control` }`}
   id='customFile'
   ref={register} 
   name="img"
 />
  <div className="error ">{errors.img?.message}</div>
 </div>

 
 
 <Progress percentage={uploadPercentage} />
 
           
           <br/>
           <button type="submit" className="btn btn-primary"  >Submit</button>
       </form>
       </div>
       </div>
    )
}

export default AddCarousel
